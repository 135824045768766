import React, {
  Dispatch,
  FC,
  HTMLAttributes,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  debounce,
  Grid2 as Grid,
  TextField,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export interface AutoCompleteOption {
  name: string;
  id: number;
}

interface AutoCompleteProps {
  fetchData: (
    input: string,
    callback: (data: AutoCompleteOption[]) => void,
    selected_country?: AutoCompleteOption | null,
    selected_university?: AutoCompleteOption | null,
  ) => void;
  noOptionsText: ReactNode;
  value: AutoCompleteOption | null;
  setValue: Dispatch<SetStateAction<AutoCompleteOption | null>>;
  disabled?: boolean;
  selected_country?: AutoCompleteOption | null;
  selected_university?: AutoCompleteOption | null;
  showIcon?: boolean;
}

const AutoComplete: FC<AutoCompleteProps> = ({
  fetchData,
  noOptionsText,
  value,
  setValue,
  disabled,
  selected_country,
  selected_university,
  showIcon = true
}) => {
  const [options, setOptions] = useState<readonly AutoCompleteOption[]>([]);
  const [inputValue, setInputValue] = useState("");

  const fetch = useMemo(() => debounce(fetchData, 400), [fetchData]);

  useEffect(() => {
    let active = true;

    fetch(
      inputValue,
      (results: readonly AutoCompleteOption[]) => {
        if (active) {
          let newOptions: readonly AutoCompleteOption[] = [];

          if (inputValue !== "" && !!value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
      selected_country,
      selected_university,
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, selected_country, selected_university]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={noOptionsText}
      sx={{
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiInputBase-fullWidth": {
          padding: "7px",
          borderRadius: "15px",
          border: "none",
        },
      }}
      onChange={(event: any, newValue: AutoCompleteOption | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={"Search..."} fullWidth />
      )}
      renderOption={(
        props: HTMLAttributes<HTMLLIElement> & { key?: string },
        option: AutoCompleteOption,
      ) => {
        if (!!props.key) {
          delete props.key;
        }
        return (
          <li key={option.id} {...props}>
            <Grid container alignItems="center">
              {showIcon && <Grid size={12} sx={{ display: "flex", width: 44 }}>
              <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>}
              <Grid
                size={12}
                sx={{ width: showIcon ? "calc(100% - 44px)" : '100%', wordWrap: "break-word" }}
              >
                <Box component="span" sx={{ fontWeight: "regular" }}>
                  {option.name}
                </Box>
              </Grid>
            </Grid>
          </li>
        );
      }}
      disabled={disabled}
    />
  );
};

export default AutoComplete;
