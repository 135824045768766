const variables = {
  preplixPrimary: "#D10724",
  sectionPrimary: "#ECF8FF",
  preplixCards: "#2D83C50D",
};

export const commonStyles = {
  pagination: {
    "& .Mui-selected": {
      backgroundColor: `${variables.preplixPrimary} !important`,
      color: "white",
    },
  },
  add_coupon_button: {
    borderRadius: "24px 0px 24px 0px",
    textTransform: "capitalize",
    ml: 1,
    mt: 1,
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    minWidth: "950px",
    maxHeight: "calc(100vh - 50px)",
    overflow: "hidden",
    overflowY: "scroll",
  },
  btnPrimary: {
    background: `${variables.preplixPrimary}`,
    marginRight: "2rem",
    marginTop: "10px",
    padding: "10px 12px",
    minWidth: "173px",
    borderRadius: "8px",

    "&:hover": {
      background: "#D9D9D9",
      color: `${variables.preplixPrimary}`,
    },
  },
  btnSecondary: {
    color: "#232326",
    background: "#D9D9D9",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginTop: "10px",
    padding: "10px 12px",
    minWidth: "173px",
    borderRadius: "8px",

    "&:hover": {
      background: `${variables.preplixPrimary}`,
      color: "white",
    },
  },
  date_picker: {
    ".MuiInputBase-root": {
      borderRadius: "15px",
      ".MuiSvgIcon-root": {
        color: variables.preplixPrimary,
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: variables.preplixPrimary,
      },
    },
  }
};
