import React, {
  FC,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  AlertColor,
  Stack,
  Pagination,
  Button,
  Popover,
  TextField,
} from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PreviewIcon from "@mui/icons-material/Preview";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoComplete, { AutoCompleteOption } from "../../../components/AutoComplete/AutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { logout, UserProfile } from "../../../utils/redux";
import { ProfileState } from "../../../utils/redux/reducer/profile-slice";
import { DropDown } from "../../../utils/constants/identifiers";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import Alert from "../../../components/Alert";
import PageLoader from "../../../components/PageLoader";
import Notification from "../../../components/Notification";
import { styles } from "./styles";
import Modal from "../../../components/modal/Modal";
import { FormModel } from "../../../components/Form";
import RenderFormFields from "../../../components/Form/helper";
import { images } from "../../../utils/constants/images";
import { AdminEditableForm, BasicInformationApplicationForm } from "./ApplicationsForm";

interface Column {
  id: keyof ApplicationListingData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const columns: Column[] = [
  { id: "studentName", label: "Student Name", minWidth: 250, align: "left" },
  { id: "teacherName", label: "Teacher Name", minWidth: 250, align: "left" },
  {
    id: "countryName",
    label: "Country",
    minWidth: 200,
    align: "left",
  },
  {
    id: "courseName",
    label: "Preferred Course",
    minWidth: 200,
    align: "left",
  },
  {
    id: "universityName",
    label: "University Name",
    minWidth: 200,
    align: "left",
  },
  {
    id: "englishTestType",
    label: "English Test Type",
    minWidth: 200,
    align: "left",
  },
  {
    id: "applicationStatusName",
    label: "Status",
    minWidth: 100,
    align: "left",
  },
];

export interface ApplicationListingData {
  id: number;
  countryName: AutoCompleteOption;
  courseName: AutoCompleteOption;
  universityName: AutoCompleteOption;
  englishTestType: number;
  applicationStatusName: string;
  studentName: string;
  nocRequests: any;
  teacherName: string;
}

interface ErrorState {
  studentId: boolean;
  teacherId: boolean;
  maritalStatus: boolean;
  universityId: boolean;
  intakeDate: boolean;
  courseId: boolean;
  passportNo: boolean;
  dob: boolean;
  englishTestType: boolean;
  dateOfTest: boolean;
  listeningRating: boolean;
  readingRating: boolean;
  writingRating: boolean;
  speakingRating: boolean;
  countryId: boolean;
}

const defaultErrorValues = {
  studentId: false,
  maritalStatus: true,
  universityId: false,
  intakeDate: true,
  courseId: false,
  passportNo: true,
  dob: true,
  englishTestType: true,
  dateOfTest: true,
  listeningRating: true,
  readingRating: true,
  writingRating: true,
  speakingRating: true,
  countryId: false,
  teacherId: true
};

const Applications: FC = () => {
  const dispatch = useDispatch();
  const profile: ProfileState = useSelector(UserProfile);
  const rowsPerPage: number = 10;

  const [applicationForm, setApplicationForm] = useState<any>();
  const [adminEditableForm, setAdminEditableForm] = useState<any>();
  const [country, setCountry] = useState<AutoCompleteOption | null>(null);
  const [student, setStudent] = useState<AutoCompleteOption | null>(null);
  const [course, setCourse] = useState<AutoCompleteOption | null>(null);
  const [university, setUniversity] = useState<AutoCompleteOption | null>(null);
  const [page, setPage] = useState<number>(1);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: ApplicationListingData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [trendCount, setTrendCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [teachers, setTeachers] = useState<DropDown[]>([]);
  const [visaStatus, setVisaStatus] = useState<DropDown[]>([]);
  const [coeStatuses, setCoeStatuses] = useState<DropDown[]>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<DropDown[]>(
    [],
  );
  const [admissionCounselor, setAdmissionCounselor] = useState<DropDown[]>([]);
  const [verficicationsStatus, setVerificationStatus] = useState<DropDown[]>([]);
  const [errorState, setErrorState] = useState<ErrorState>(defaultErrorValues);
  const [mode, setMode] = useState<"Add" | "Edit">("Add");
  const [editId, setEditId] = useState<null | string>(null);
  const [documentModal, setDocumentModal] = useState<boolean>(false);
  const [documents, setDocuments] = useState<
    { category: string; isUploaded: boolean }[]
  >([]);
  const [showNocList, setShowNocList] = useState(false);
  const [nocList, setNocList] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const popOverOpen = Boolean(anchorEl);
  const [nocReason, setNocReason] = useState("");
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const popOverId = popOverOpen ? "simple-popover" : undefined;
  const [formDocumentList, setFormDocumentList] = useState<
    { category: string; isUploaded: boolean }[]
  >([]);
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });

  const getData = useCallback(async () => {
    try {
      const body = {
        filter: [],
        graphql: null,
        page: page,
        size: rowsPerPage,
        sort: [`id:asc`],
      };

      interface ApiData extends ApplicationListingData {
        applicationCountry: AutoCompleteOption;
        applicationCourse: AutoCompleteOption;
        applicationUniversity: AutoCompleteOption;
      }

      const {
        data,
      }: {
        status: number;
        data: {
          size: number;
          content: ApiData[];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: number;
          totalElements: number;
          numberOfElements: number;
          numberOfElementsInDatabase: number;
        };
      } = await axiosInstance.post(URLS.applications("admin", "/page"), body);
      const listings: ApplicationListingData[] = [];
      data.content.forEach((listing) => {
        listings.push({
          id: listing.id,
          countryName: listing.countryName,
          courseName: listing.courseName,
          universityName: listing.universityName,
          englishTestType: listing.englishTestType,
          studentName: listing.studentName,
          applicationStatusName: listing.applicationStatusName,
          nocRequests: listing.nocRequests || [],
          teacherName: listing.teacherName
        });
      });

      setListing({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        listings,
      });
      setTrendCount(data.numberOfElements);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch, page]);

  const getStudents = async (
    input: string,
    callback: (data: AutoCompleteOption[]) => void,
  ) => {
    try {
      const { data } = await axiosInstance.get(`${URLS.allStudentsDropDown}?name=${input}`);
      callback(data as AutoCompleteOption[]);
    } catch (e: any) {
      setOpen(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  };

  const getTeachers = async () => {
    try {
      const { data } = await axiosInstance.get(URLS.studentSpecificTeacher(student?.id as number));
      if(data){
      setTeachers(
        data?.map((teacher: { name: string; id: number }) => ({
          label: teacher?.name,
          value: teacher?.id?.toString(),
        })),
      );
    }
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  };

  const getCountries = async (
    input: string,
    callback: (data: AutoCompleteOption[]) => void,
  ) => {
    try {
      const { data } = await axiosInstance.get(
        URLS.applications("students", `/countries?name=${input}`),
      );
      callback(data as AutoCompleteOption[]);
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      callback([]);
      setLoading(false);
    }
  };

  const getUniversities = async (
    input: string,
    callback: (data: AutoCompleteOption[]) => void,
    selected_country?: AutoCompleteOption | null,
  ) => {
    if (!!selected_country) {
      try {
        const { data } = await axiosInstance.get(
          URLS.applications(
            "students",
            `/countries/${selected_country.id}/universities?name=${input}`,
          ),
        );
        callback(data as AutoCompleteOption[]);
      } catch (e: any) {
        console.log(e);
        if (e.response) {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: "Something went wrong. Please try again after sometime",
          });
        }
        callback([]);
        setLoading(false);
      }
    }
  };

  const getCourses = async (
    input: string,
    callback: (data: AutoCompleteOption[]) => void,
    selected_country?: AutoCompleteOption | null,
    selected_university?: AutoCompleteOption | null,
  ) => {
    if (!!selected_country && !!selected_university) {
      try {
        const { data } = await axiosInstance.get(
          URLS.applications(
            "students",
            `/countries/universities/${selected_university.id}/courses?name=${input}`,
          ),
        );
        callback(data as AutoCompleteOption[]);
      } catch (e: any) {
        console.log(e);
        if (e.response) {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: "Something went wrong. Please try again after sometime",
          });
        }
        callback([]);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const handleDelete = async (row: ApplicationListingData) => {
    Alert({
      title: `Are you Sure you want to Delete this Application?`,
      html: `<p><strong>Student Name: </strong>${row.studentName}</p><p><strong>University Preference: </strong>${row.universityName}</p><p><strong>Preferred Course: </strong>${row.courseName}</p><p><strong>Location: </strong>${row.countryName}</p>`,
      confirmButtonText: "Yes, Delete",
      confirmButtonColor: "#D10724",
      showCancelButton: true,
      cancelButtonText: "No, Take me Back",
      cancelButtonColor: "#D9D9D9",
      reverseButtons: true,
      customClass: { cancelButton: "delete-popup-cancel" },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteApplication(row.id.toString());
      }
    });
  };

  const deleteApplication = async (id: string) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.delete(
        URLS.applications("students", `/${id}`),
      );
      if (status === 204) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Application Deleted Successfully",
        });
        await getData();
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const resetForm = () => {
    setErrorState(defaultErrorValues);
    setApplicationForm(undefined);
    setAdminEditableForm(undefined);
    setCountry(null);
    setStudent(null);
    setUniversity(null);
    setCourse(null);
    setMessage({
      display: false,
      severity: "",
      message: "",
    });
  };

  const getDataById = async (id: string, flow: string) => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(
        URLS.applications("students", `/${id}`),
      );
      if (status === 200) {
        setLoading(false);
        if (flow === "edit") {
          setApplicationForm({
            ...data,
            studentId: data.student.value,
            maritalStatus: data.maritalStatus?.value,
            englishTestType: data.englishTestType?.value,
            
          });
          setUniversity({
            id: data.university.value,
            name: data.university.label,
          });
          setCountry({ id: data.country.value, name: data.country.label });
          setCourse({ id: data.course.value, name: data.course.label });
          setStudent({ id: data.student.value, name: data.student.label });
          setAdminEditableForm(
            {
              visaStatus: data.visaStatus?.value,
              applicationStatus: data?.applicationStatus?.value,
              verificationStatus: data?.verificationStatus?.value,
              coeStatus: data.coeStatus?.value,
              admissionCounselor: data.admissionCounselor?.value,
              visaRefusalReason: data.visaRefusalReason
            }
          )
          
          setErrorState({
            studentId: false,
            countryId: false,
            universityId: false,
            courseId: false,
            dob: false,
            maritalStatus: false,
            intakeDate: false,
            englishTestType: false,
            dateOfTest: false,
            listeningRating: false,
            readingRating: false,
            writingRating: false,
            speakingRating: false,
            passportNo: false,
            teacherId: false
          });
          setMode("Edit");
          setEditId(id);
          setOpen(true);
        } else if (flow === "document") {
          if (data.documents) {
            setDocumentModal(true);
            setDocuments(data.documents);
          }
        }
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const validateForm = async () => {
    let invalid = false;
    if (!!errorState) {
      Object.keys(errorState).forEach((key: string) => {
        if (errorState[key as keyof ErrorState]) {
          invalid = true;
        }
      });
    }

    if (!invalid) {
      await addApplication();
    } else {
      setMessage({
        display: true,
        severity: "error",
        message: `Please fill all basic information correctly!`,
      });
    }
  };

  const closeDocumentModal = () => {
    setDocumentModal(false);
    setDocuments([]);
  };

  const addApplication = async () => {
    try {
      if (!!country && !!university && !!course) {
        setLoading(true);
        if (mode === "Add") {
          if(student){
          const { status } = await axiosInstance.post(
            URLS.applications("admin", ""),
            {
              ...applicationForm,
              countryId: country.id,
              courseId: course.id,
              universityId: university.id,
              studentId: student.id,
            },
          );
          if (status === 201) {
            setLoading(false);
            setMessage({
              display: true,
              severity: "success",
              message: "Application Added Successfully",
            });
            resetForm();
            await closeModal(true);
          }
        }else{
          setMessage({
            display: true,
            severity: "error",
            message: "Please select student!",
          });
          setLoading(false);
        }
        } else {
          console.log("applicationForm::",applicationForm);
          
          await axiosInstance.put(
            URLS.applications("admin", `/${editId as string}`),
            {
              maritalStatus: applicationForm.maritalStatus,
              countryId: country.id,
              universityId: university.id,
              intakeDate: applicationForm.intakeDate,
              courseId: course.id,
              passportNo: applicationForm.passportNo,
              dob: applicationForm.dob,
              englishTestType: applicationForm.englishTestType,
              dateOfTest: applicationForm.dateOfTest,
              listeningRating: applicationForm.listeningRating,
              readingRating: applicationForm.readingRating,
              speakingRating: applicationForm.speakingRating,
              writingRating: applicationForm.writingRating,
              admissionCounselor: adminEditableForm.admissionCounselor,
              applicationStatus: adminEditableForm.applicationStatus,
              verificationStatus: adminEditableForm.verificationStatus,
              coeStatus: adminEditableForm.coeStatus,
              visaStatus: adminEditableForm.visaStatus,
              visaRefusalReason: adminEditableForm.visaRefusalReason
            },
          );
          setEditId(null);
          setLoading(false);
          setMessage({
            display: true,
            severity: "success",
            message: "Application Edited Successfully",
          });
          resetForm();
          await closeModal(true);
        }
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const closeModal = async (flag?: boolean) => {
    if (!!flag) {
      await getData();
    }
    setOpen(false); // Close the modal
  };

  const getUploadedDocuments = async () => {
    try {
      const { data } = await axiosInstance.get(
        URLS.getUploadedDocuments(student?.id as number),
      );
      if (data) {
        setFormDocumentList(data);
      }
    } catch (e) {
      setFormDocumentList([]);
    }
  };

  useEffect(() => {
    (async () => {
      if (student) {
        await getUploadedDocuments();
        await getTeachers();
      }else{
        setTeachers([]);
        if(applicationForm && applicationForm.teacherId){
          setApplicationForm({...applicationForm, teacherId:null })
          setErrorState({...errorState,teacherId:true});
        }
      }
    })();
  }, [student]);

  const openPopup = async () => {
    setEditId(null);
    setMode("Add");
    resetForm();
    setOpen(true);
  };

  const getApplicationDropdowns = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.applications_dropdown);
      setVisaStatus(
        data?.visaStatuses?.map((visa: { name: string; id: number }) => ({
          label: visa?.name,
          value: visa?.id?.toString(),
        })),
      );
      setApplicationStatuses(
        data?.applicationStatuses?.map(
          (visa: { name: string; id: number }) => ({
            label: visa?.name,
            value: visa?.id?.toString(),
          }),
        ),
      );
      setCoeStatuses(
        data.coeStatuses.map((visa: { name: string; id: number }) => ({
          label: visa?.name,
          value: visa?.id?.toString(),
        })),
      );
      setVerificationStatus(
        data.verificationStatuses.map((visa: { name: string; id: number }) => ({
          label: visa?.name,
          value: visa?.id?.toString(),
        })),
      );
      setAdmissionCounselor(
        data.admissionCounselors.map((visa: { name: string; id: number }) => ({
          label: visa?.name,
          value: visa?.id?.toString(),
        })),
      );
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getApplicationDropdowns();
    })();
  }, [getApplicationDropdowns]);


  const handleNocApprove = async (nocId: number | string) => {
    try {
      if (selectedId) {
        setLoading(true);
        await axiosInstance.post(URLS.nocStatus(selectedId), {
          comment: nocReason,
          nocRequestId: nocId,
          status: "APPROVED",
        });
        setAnchorEl(null);
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Approved successfully!",
        });
        setNocList(null);
        setShowNocList(false);
        setSelectedId(null);
        await getData();
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Grid
        container
        spacing={4}
        justifyContent={"space-evenly"}
        sx={[{ py: 3 }]}
      >
        <Grid item lg={2}>
          <Card
            sx={{
              minHeight: "145px",
              borderRadius: "12px",
              background: "linear-gradient(180deg, #E30928 0%, #820517 100%)",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={openPopup}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 0,
                "&:last-child": {
                  paddingBottom: 0,
                },
              }}
            >
              <img
                src={images.goAdd}
                alt="addListing"
                className={"logo-icon-dashboard"}
              />
              <Typography
                variant="h5"
                textAlign="center"
                fontWeight="700"
                fontSize="20px"
              >
                Add New Application
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={8}>
          <Box
            sx={{
              borderRadius: "12px",
              background: "linear-gradient(180deg, #E30928 0%, #820517 100%)",
              color: "white",
              minHeight: "145px",
            }}
          >
            <Grid container>
              <Grid
                item
                lg={8}
                sx={{ paddingLeft: "25px" }}
                alignContent={"center"}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  fontWeight="700"
                  fontSize="20px"
                >
                  Welcome back {profile.firstName}
                </Typography>
              </Grid>
              <Grid item lg={4}>
                <img
                  src={images.preachIcon}
                  alt="teaching"
                  className={"full-height"}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item lg={2}>
          <Card
            sx={{
              display: "flex",
              minHeight: "145px",
              borderRadius: "12px",
              color: "black",
              border: "1px solid #D10724",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 0,
                "&:last-child": {
                  paddingBottom: 0,
                },
              }}
            >
              <img
                src={images.growthTrend}
                alt="addListing"
                className={"logo-icon-dashboard"}
              />
              <Typography
                gutterBottom
                variant={"h5"}
                textAlign={"center"}
                fontWeight={600}
                fontSize={"20px"}
                color={"#095089"}
                mt={2}
              >
                {trendCount}
              </Typography>
              <Typography
                gutterBottom
                variant={"h5"}
                textAlign={"center"}
                fontWeight={400}
                fontSize={"14px"}
                color={"#9D9D9D"}
              >
                Applications Created
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "calc(100vw - 105px)",
        }}
      >
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 400px)", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{
                      minWidth: column.minWidth,
                      background: "#EBEBEB",
                      borderBottom: "1px solid #9D9D9D",
                      fontSize: "18px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  align={"center"}
                  style={{
                    minWidth: 200,
                    background: "#EBEBEB",
                    borderBottom: "1px solid #9D9D9D",
                    fontSize: "18px",
                  }}
                >
                  Documents
                </TableCell>
                <TableCell
                  align={"center"}
                  style={{
                    minWidth: 200,
                    background: "#EBEBEB",
                    borderBottom: "1px solid #9D9D9D",
                    fontSize: "18px",
                  }}
                >
                  NOC List
                </TableCell>
                <TableCell
                  align={"center"}
                  style={{
                    minWidth: 200,
                    background: "#EBEBEB",
                    borderBottom: "1px solid #9D9D9D",
                    fontSize: "18px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listing &&
                listing.listings.length > 0 &&
                listing.listings.map((row, index) => (
                  <TableRow
                    style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {columns.map((column, i) => {
                      let value =
                        column.id === "englishTestType" && row[column.id]
                          ? (row[column.id] as unknown as string).toUpperCase()
                          : row[column.id];
                      let textStyle: CSSProperties = {
                        fontSize: "16px",
                        fontWeight: "600",
                      };

                      if (column.id === "applicationStatusName") {
                        if (value === "pending") {
                          textStyle = {
                            ...textStyle,
                            textTransform: "capitalize",
                            color: "#FF9924",
                            backgroundColor: "#FF992426",
                            borderRadius: "10px",
                            padding: "10px",
                          };
                        } else if (value === "approved") {
                          textStyle = {
                            ...textStyle,
                            textTransform: "capitalize",
                            color: "#126616",
                            backgroundColor: "#12661626",
                            borderRadius: "10px",
                            padding: "10px",
                          };
                        }
                      }
                      return (
                        <TableCell
                          key={i}
                          align="center"
                          sx={{ padding: "25px" }}
                        >
                          <Typography sx={textStyle}>
                            {value as string}
                          </Typography>
                        </TableCell>
                      );
                    })}
                    <TableCell align="center" sx={{ padding: "25px" }}>
                      <IconButton
                        color="default"
                        aria-label="edit"
                        onClick={() =>
                          getDataById(row.id.toString(), "document")
                        }
                      >
                        <PageviewIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" sx={{ padding: "25px" }}>
                      {row.nocRequests && row.nocRequests.length > 0 && (
                        <IconButton
                          color="default"
                          aria-label="edit"
                          onClick={() => {
                            setShowNocList(true);
                            setNocList(row.nocRequests);
                            setSelectedId(row.id);
                          }}
                        >
                          <PageviewIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell align={"center"} sx={{ padding: "25px" }}>
                      <IconButton
                        key="edit"
                        color="default"
                        aria-label="edit"
                        onClick={() => getDataById(row.id.toString(), "edit")}
                      >
                        <img
                          src={images.edit}
                          alt="edit"
                          className={"logo-icon-dashboard"}
                        />
                      </IconButton>
                      <IconButton
                        key="delete"
                        color="default"
                        aria-label="delete"
                        onClick={() => handleDelete(row)}
                      >
                        <img
                          src={images.trash}
                          alt="addListing"
                          className={"logo-icon-dashboard"}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={[{ padding: "15px 0" }]}>
          <Grid
            item
            lg={12}
            sx={[
              {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            <div
              style={{
                width: "fitContent",
                background: "#FDF3F4",
                borderRadius: "24px",
                padding: "20px",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  sx={styles.pagination}
                  count={listing.totalPages}
                  defaultPage={1}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        setModalClose={closeModal}
        size={"md"}
        open={open}
        title={"Add New Application"}
        className=""
      >
        <Container>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Basic Information
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {BasicInformationApplicationForm(teachers, mode)
                  .map((item: FormModel) => ({
                    ...item,
                    value: !!applicationForm
                      ? (applicationForm[item.field] as string | boolean)
                      : "",
                  }))
                  .map((item: FormModel, index: number) => (
                    <Grid item key={index} xs={6} padding={2}>
                      <Typography
                        fontWeight={600}
                        fontSize={"18px"}
                        color="#545454"
                      >
                        {item.label}
                      </Typography>
                      {item.type === "auto-complete" ? (
                        <>
                          {item.field === "studentId" && (
                            <AutoComplete
                              showIcon={false}
                              fetchData={getStudents}
                              noOptionsText="No Students"
                              value={student}
                              setValue={setStudent}
                            />
                          )}
                          {item.field === "countryId" && (
                            <AutoComplete
                              fetchData={getCountries}
                              noOptionsText="No Countries"
                              value={country}
                              setValue={setCountry}
                            />
                          )}
                          {item.field === "universityId" && (
                            <AutoComplete
                              fetchData={getUniversities}
                              noOptionsText="No Universities"
                              value={university}
                              setValue={setUniversity}
                              disabled={!country}
                              selected_country={country}
                            />
                          )}
                          {item.field === "courseId" && (
                            <AutoComplete
                              fetchData={getCourses}
                              noOptionsText="No Courses"
                              value={course}
                              setValue={setCourse}
                              disabled={!country && !university}
                              selected_country={country}
                              selected_university={university}
                            />
                          )}
                        </>
                      ) : item.type === "drop-down" ? (
                        <>
                          <RenderFormFields
                            value={item.value as string}
                            item={item}
                            handleChange={(
                              value: string,
                              field: string,
                              error?: { error: boolean; message: string },
                            ) => {
                              const data = {
                                [field]: value,
                              };

                              setErrorState((prevState) => ({
                                ...prevState,
                                [field]: !!(error && error.error),
                              }));
                              setApplicationForm((prevState: any) => ({
                                ...prevState,
                                ...data,
                              }));
                            }}
                          />
                          {item.value === "others" && (
                            <RenderFormFields
                              value={item.value as string}
                              item={{
                                ...item,
                                type: "text",
                                label: "Please specify",
                              }}
                              handleChange={(
                                value: string,
                                field: string,
                                error?: { error: boolean; message: string },
                              ) => {
                                const data = {
                                  [field]: value,
                                };

                                setErrorState((prevState) => ({
                                  ...prevState,
                                  [field]: !!(error && error.error),
                                }));
                                setApplicationForm((prevState: any) => ({
                                  ...prevState,
                                  ...data,
                                }));
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <RenderFormFields
                          value={item.value as string}
                          item={item}
                          handleChange={(
                            value: string,
                            field: string,
                            error?: { error: boolean; message: string },
                          ) => {
                            const data = {
                              [field]: value,
                            };

                            setErrorState((prevState) => ({
                              ...prevState,
                              [field]: !!(error && error.error),
                            }));

                            setApplicationForm((prevState: any) => ({
                              ...prevState,
                              ...data,
                            }));
                          }}
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Documentation
            </AccordionSummary>
            <AccordionDetails>
              <Container>
                {!student ? (
                  <Typography>Please select student</Typography>
                ) : (
                  <Grid container>
                    {formDocumentList.length > 0 &&
                      formDocumentList.map((document) => {
                        return (
                          <Grid
                            item
                            display={"flex"}
                            xs={6}
                            key={document.category}
                            mt={1}
                          >
                            <Typography
                              noWrap
                              flex={1.2}
                              title={document.category}
                            >
                              {document.category}
                            </Typography>
                            <Typography flex={1} textAlign={"center"}>
                              {document.isUploaded ? (
                                <CheckCircleIcon sx={{ color: "#4BB543" }} />
                              ) : (
                                <CancelIcon sx={{ color: "#ff2c2c" }} />
                              )}
                            </Typography>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </Container>
            </AccordionDetails>
          </Accordion>
          {mode === "Edit" && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Status
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {AdminEditableForm(coeStatuses,verficicationsStatus,admissionCounselor,applicationStatuses,visaStatus)
                    .map((item: FormModel) => ({
                      ...item,
                      value: !!adminEditableForm
                        ? (adminEditableForm[item.field] as string | boolean)
                        : "",
                    }))
                    .map((item: FormModel, index: number) => (
                      <Grid item key={index} xs={6} padding={2}>
                        <Typography
                          fontWeight={600}
                          fontSize={"18px"}
                          color="#545454"
                        >
                          {item.label}
                        </Typography>
                        <RenderFormFields
                          value={item.value as string}
                          item={item}
                          handleChange={(
                            value: string,
                            field: string,
                            error?: { error: boolean; message: string },
                          ) => {
                            const data = {
                              [field]: value,
                            };
                            setAdminEditableForm((prevState: any) => ({
                              ...prevState,
                              ...data,
                            }));
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          <Grid container marginTop={4}>
            <Grid item xs={12} textAlign={"end"}>
              <Button
                variant="contained"
                onClick={validateForm}
                sx={{
                  borderRadius: "5rem",
                  backgroundColor: "#D10724",
                  paddingX: 4,
                  "&:hover": {
                    backgroundColor: "#D10724",
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Modal>
      <Modal
        setModalClose={closeDocumentModal}
        size={"md"}
        open={documentModal}
        title={"Documents"}
        className=""
      >
        <Container>
          <Grid container>
            {documents.length > 0 &&
              documents.map((document) => {
                return (
                  <Grid
                    item
                    display={"flex"}
                    xs={6}
                    key={document.category}
                    mt={1}
                  >
                    <Typography noWrap flex={1.2}>
                      {document.category}
                    </Typography>
                    <Typography flex={1} textAlign={"center"}>
                      {document.isUploaded ? (
                        <CheckCircleIcon sx={{ color: "#4BB543" }} />
                      ) : (
                        <CancelIcon sx={{ color: "#ff2c2c" }} />
                      )}
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Modal>

      <Modal
        setModalClose={() => {
          setNocList(null);
          setShowNocList(false);
          setSelectedId(null);
          setAnchorEl(null);
        }}
        size={"md"}
        open={showNocList}
        title={"NOC Requests"}
        className=""
      >
        <Container>
          <Box display={"flex"} alignItems={"center"} textAlign={"center"}>
            <Typography flex={1} fontWeight={"bold"}>
              Status
            </Typography>
            <Typography flex={1} fontWeight={"bold"}>
              New Teahcer
            </Typography>
            <Typography flex={1} fontWeight={"bold"}>
              Document
            </Typography>
            <Box width={"110px"}>
              <Typography fontWeight={"bold"}>Approve</Typography>
            </Box>
          </Box>
          {nocList &&
            nocList.length > 0 &&
            nocList.map((noc: any, index: number) => {
              return (
                <Box
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  textAlign={"center"}
                >
                  <Typography flex={1}>{noc.status}</Typography>
                  <Typography flex={1}>{noc.newTeacherName}</Typography>
                  <Box flex={1}>
                    <IconButton
                      onClick={() => window.open(noc.documentUrl, "_blank")}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Box>
                  <Box width={"110px"}>
                    {noc?.status?.toLowerCase() === "pending" && (
                      <Button
                        variant="contained"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>,
                        ) => {
                          setAnchorEl(event.currentTarget);
                        }}
                        sx={{
                          height: "27px",
                          borderRadius: "15px",
                          backgroundColor: "#D10724",
                          paddingX: 2,
                          "&:hover": {
                            backgroundColor: "#D10724",
                          },
                        }}
                      >
                        Approve
                      </Button>
                    )}
                    <Popover
                      id={popOverId}
                      open={popOverOpen}
                      anchorEl={anchorEl}
                      onClose={() => {
                        setAnchorEl(null);
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Box p={2}>
                        <TextField
                          value={nocReason}
                          onChange={(e) => setNocReason(e.target.value)}
                          placeholder="Reason"
                          sx={{
                            backgroundColor: "#FFFFFF",
                            marginBottom: 0,
                            borderRadius: "15px",
                            border: "1px solid #FFA7B7",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          inputProps={{
                            sx: {
                              border: "none",
                              padding: "15px",
                              paddingLeft: "15px",
                            },
                          }}
                        ></TextField>
                        <Box mt={1} textAlign={"center"}>
                          <Button
                            variant="contained"
                            onClick={async () => {
                              if (!!nocReason) {
                                await handleNocApprove(noc.id);
                              }
                            }}
                            sx={{
                              height: "27px",
                              borderRadius: "15px",
                              backgroundColor: "#D10724",
                              paddingX: 2,
                              "&:hover": {
                                backgroundColor: "#D10724",
                              },
                            }}
                          >
                            Confirm
                          </Button>
                        </Box>
                      </Box>
                    </Popover>
                  </Box>
                </Box>
              );
            })}
        </Container>
      </Modal>
    </Container>
  );
};

export default Applications;
