import React, {
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  AlertColor,
  Stack,
  Pagination,
  IconButton,
  Modal,
  Divider,
  Checkbox,
  Radio,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { logout, UserProfile } from "../../../utils/redux";
import PageLoader from "../../../components/PageLoader";
import Notification from "../../../components/Notification";
import PrimaryButton from "../../../components/PrimaryButton";
import Switch from "@mui/material/Switch";
import { commonStyles } from "../../../utils/constants/commonStyles";
import { Close } from "@mui/icons-material";
import Button from "../../../components/Button/Button";
import { images } from "../../../utils/constants/images";
import { CouponForm } from "./CouponForm";
import { FormModel } from "../../../components/Form";
import RenderFormFields from "../../../components/Form/helper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteIcon from "@mui/icons-material/EditNote";

interface Column {
  id: keyof ListingData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

export interface ListingData {
  id: number;
  code: string;
  discountType: string;
  discountAmount: number;
  applicableOn: string;
  customerType: string;
  description: string;
  usageLimit: number;
  minPurchaseAmount: number;
  expiryDate: string;
  isActive: boolean;
}

const columns: Column[] = [
  { id: "id", label: "Coupon ID", minWidth: 100, align: "left" },
  {
    id: "code",
    label: `Coupon Code`,
    minWidth: 150,
    align: "left",
  },
  {
    id: "discountType",
    label: `Coupon Type`,
    minWidth: 150,
    align: "left",
  },
  {
    id: "discountAmount",
    label: "Discount value",
    minWidth: 150,
    align: "left",
  },
  { id: "applicableOn", label: "Applicable On", minWidth: 150, align: "left" },
  { id: "customerType", label: "Customer Type", minWidth: 150, align: "left" },
  { id: "description", label: "Description", minWidth: 150, align: "left" },
  { id: "usageLimit", label: "Count of Usage", minWidth: 150, align: "left" },
  {
    id: "minPurchaseAmount",
    label: "Min Cart Value",
    minWidth: 150,
    align: "left",
  },
  { id: "expiryDate", label: "Validity", minWidth: 180, align: "left" },
  {
    id: "isActive",
    label: "Actions",
    minWidth: 160,
    align: "left",
  },
];

interface ErrorState {
  discountType: boolean;
  discountAmount: boolean;
  applicableOn: boolean;
  description: boolean;
  usageLimit: boolean;
  minPurchaseAmount: boolean;
}

const defaultErrorValues = {
  discountType: true,
  discountAmount: true,
  applicableOn: true,
  description: true,
  usageLimit: true,
  minPurchaseAmount: true,
};

const Coupons = () => {
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: ListingData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const [open, setOpen] = useState(false);
  const [couponForm, setCouponForm] = useState<any>();
  const [range, setRange] = useState<string>("");
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const today = new Date();
  const [errorState, setErrorState] = useState<ErrorState>(defaultErrorValues);
  const [customerType, setCustomerType] = useState({
    teacher: false,
    student: false,
    plc: false,
  });
  const profile = useSelector(UserProfile);
  const [mode, setMode] = useState<"add" | "edit">("add");

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        page: page,
        size: rowsPerPage,
        sort: [`id:desc`],
      };
      const { data } = await axiosInstance.post(URLS.couponListing, body);
      setLoading(false);
      setListing({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        listings: data.content,
      });
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch, page]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleEditModel = (row: ListingData) => {
    setCouponForm(row);
    handleOpenModel("edit");
    setRange("Date Range");
    setEndDate(dayjs(row.expiryDate));
    const updatedCustomerType: {
      teacher: boolean;
      student: boolean;
      plc: boolean;
    } = { ...customerType };
    row.customerType.split(",").forEach((type) => {
      const typeLowerCase = type.toLowerCase() as keyof {
        teacher: boolean;
        student: boolean;
        plc: boolean;
      };
      if (updatedCustomerType[typeLowerCase] !== undefined) {
        updatedCustomerType[typeLowerCase] = true;
      }
    });
    setCustomerType(updatedCustomerType);
    setErrorState({
      discountType: false,
      discountAmount: false,
      applicableOn: false,
      description: false,
      usageLimit: false,
      minPurchaseAmount: false,
    });
  };

  const handleOpenModel = (mode: "add" | "edit") => {
    setMode(mode);
    setOpen(true);
  };

  const handleCloseModel = () => {
    setOpen(false);
    setCustomerType({
      teacher: false,
      student: false,
      plc: false,
    });
    setCouponForm(undefined);
    setRange("");
    setMode("add");
    setEndDate(dayjs());
    setErrorState(defaultErrorValues);
  };

  const validateForm = async () => {
    let invalid = false;
    if (!!errorState) {
      Object.keys(errorState).forEach((key: string) => {
        if (errorState[key as keyof ErrorState]) {
          invalid = true;
        }
      });
    }

    if (invalid || range === "") {
      setMessage({
        display: true,
        severity: "error",
        message: `Please fill values correctly!`,
      });
    } else {
      await handleCreateAndEditCoupon();
    }
  };

  const handleCreateAndEditCoupon = async () => {
    try {
      if (customerType.teacher || customerType.student || customerType.plc) {
        setLoading(true);
        const selectedCustomerTypes = Object.keys(customerType)
          .filter((key) => customerType[key as keyof typeof customerType])
          .map((key) => key.toUpperCase())
          .join(",");

        const indefiniteDate = new Date(today);
        indefiniteDate.setDate(today.getDate() + 91);
        const body = {
          // referrerId: 0,
          isActive: true,
          ...couponForm,
          customerType: selectedCustomerTypes,
          userId: profile.id,
          expiryDate:
            range === "Indefinite"
              ? indefiniteDate
              : new Date(dayjs(endDate).format("YYYY-MM-DD")),
        };
        const { status } = mode==="add" ? await axiosInstance.post(URLS.createCoupon, body) : await axiosInstance.put(URLS.updateCoupon(couponForm.id), body);
        setLoading(false);
        if (status === 200) {
          setMessage({
            display: true,
            severity: "success",
            message: `Coupon ${mode==="add" ? "Created" : "Updated"} Successfully`,
          });
        }
        await getData();
        handleCloseModel();
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: `Please select customer type!`,
        });
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const changeCouponStatus = async (id: number, isActive: boolean) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.put(
        URLS.couponStatus(id, !isActive)
      );
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Status Changed Successfully",
        });
        await getData();
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#f4fbff",
        marginTop: "20px",
        padding: "20px",
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <Box>
        {loading && <PageLoader />}
        {message.display && (
          <Notification
            isOpen={message.display}
            message={message.message}
            severity={message.severity as AlertColor}
            closeNotification={closeNotification}
          />
        )}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={2}
        >
          <Typography variant="h5" fontWeight="700" color={"#000000"}>
            Coupons
          </Typography>
          <PrimaryButton
            fullWidth={false}
            color={"secondary"}
            sx={commonStyles.add_coupon_button}
            onClick={() => {
              handleOpenModel("add");
            }}
          >
            Create New Coupon
          </PrimaryButton>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            maxWidth: "calc(100vw - 105px)",
          }}
        >
          <TableContainer
            sx={{ maxHeight: "calc(100vh - 400px)", overflow: "auto" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{
                        minWidth: column.minWidth,
                        background: "#EBEBEB",
                        borderBottom: "1px solid #9D9D9D",
                        fontSize: "18px",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listing.listings.map((row) => (
                  <TableRow
                    style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                    hover
                    tabIndex={-1}
                    key={row.id}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      let textStyle: CSSProperties = {
                        fontSize: "16px",
                        fontWeight: "600",
                      };
                      return column.id === "isActive" ? (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ padding: "25px" }}
                        >
                          <Switch
                            checked={value as boolean}
                            onChange={() => {
                              changeCouponStatus(row.id, value as boolean);
                            }}
                          />
                          <IconButton onClick={() => handleEditModel(row)}>
                            <EditNoteIcon sx={{ fontSize: "35px" }} />
                          </IconButton>
                        </TableCell>
                      ) : column.id === "expiryDate" ? (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ padding: "25px" }}
                        >
                          <Typography sx={textStyle}>
                            {value
                              ? dayjs(value as string).format("DD-MM-YYYY")
                              : "-"}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ padding: "25px" }}
                        >
                          <Typography sx={textStyle}>{value}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container sx={[{ padding: "15px 0" }]}>
            <Grid
              item
              lg={12}
              sx={[
                {
                  display: "flex",
                  justifyContent: "center",
                },
              ]}
            >
              <div
                style={{
                  width: "fitContent",
                  background: "#FDF3F4",
                  borderRadius: "24px",
                  padding: "20px",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    sx={commonStyles.pagination}
                    count={listing.totalPages}
                    defaultPage={1}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Modal open={open} onClose={() => handleCloseModel()}>
        <Box sx={commonStyles.modalContent}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create New Coupon
            </Typography>

            <IconButton onClick={() => handleCloseModel()}>
              <Close />
            </IconButton>
          </Box>
          <Divider />

          <Grid container maxWidth={"lg"} sx={{ m: 2 }}>
            {CouponForm(couponForm)
              .map((item: FormModel) => ({
                ...item,
                value: !!couponForm
                  ? (couponForm[item.field as keyof ListingData] as
                      | string
                      | boolean)
                  : "",
              }))
              .map((item: FormModel, index: number) => (
                <Grid key={index} padding={2} lg={6}>
                  <Typography
                    fontWeight={600}
                    fontSize={"18px"}
                    color="#545454"
                  >
                    {item.label}
                  </Typography>
                  {item.field === "customerType" ? (
                    <Box display={"flex"} alignItems={"center"}>
                      <Checkbox
                        checked={customerType.teacher}
                        onChange={(e) => {
                          setCustomerType({
                            ...customerType,
                            teacher: e.target.checked,
                          });
                        }}
                      />
                      <Typography>Teacher</Typography>
                      <Checkbox
                        checked={customerType.student}
                        onChange={(e) => {
                          setCustomerType({
                            ...customerType,
                            student: e.target.checked,
                          });
                        }}
                      />
                      <Typography>Student</Typography>
                      <Checkbox
                        checked={customerType.plc}
                        onChange={(e) => {
                          setCustomerType({
                            ...customerType,
                            plc: e.target.checked,
                          });
                        }}
                      />
                      <Typography>PLC</Typography>
                    </Box>
                  ) : (
                    <RenderFormFields
                      value={item.value as string}
                      item={item}
                      handleChange={async (
                        value: string,
                        field: string,
                        error?: { error: boolean; message: string }
                      ) => {
                        const data = {
                          [field]: value,
                        };

                        if (field === "discountType") {
                          setCouponForm((prevState: any) => ({
                            ...prevState,
                            ...data,
                            discountAmount: "",
                          }));
                          setErrorState((prevState) => ({
                            ...prevState,
                            discountAmount: true,
                          }));
                        } else if (
                          field === "discountAmount" &&
                          couponForm?.discountType === "PERCENTAGE" &&
                          Number(data?.discountAmount) > 100
                        ) {
                          setMessage({
                            display: true,
                            severity: "error",
                            message: "Percentage coupon can't be more than 100",
                          });
                        } else {
                          setCouponForm((prevState: any) => ({
                            ...prevState,
                            ...data,
                          }));
                        }
                        setErrorState((prevState) => ({
                          ...prevState,
                          [field]: !!(error && error.error),
                        }));
                      }}
                    />
                  )}
                </Grid>
              ))}
            <Grid lg={12} padding={"16px 16px 5px 16px"}>
              <Typography fontWeight={600} fontSize={"18px"} color="#545454">
                Select Validity
              </Typography>
            </Grid>
            <Grid padding={"0px 16px"} lg={6}>
              <Stack direction={"row"}>
                <Box>
                  <Radio
                    checked={range === "Date Range"}
                    value="Date Range"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setRange((event.target as HTMLInputElement).value)
                    }
                  />
                </Box>
                <Box padding={1}>
                  <Typography
                    variant="body1"
                    fontWeight={range === "Date Range" ? 700 : 400}
                    color={"#000000"}
                  >
                    Select the Date Range
                  </Typography>
                </Box>
              </Stack>
              {range === "Date Range" && (
                <Stack direction={{ xs: "column", lg: "row" }} pl={"15px"}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box>
                      <DatePicker
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        minDate={dayjs(today)}
                        sx={commonStyles.date_picker}
                      />
                    </Box>
                  </LocalizationProvider>
                </Stack>
              )}
            </Grid>
            <Grid padding={"0px 16px"} lg={6}>
              <Stack direction={"row"}>
                <Box>
                  <Radio
                    checked={range === "Indefinite"}
                    value="Indefinite"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setRange((event.target as HTMLInputElement).value)
                    }
                  />
                </Box>
                <Box padding={1}>
                  <Typography
                    variant="body1"
                    fontWeight={range === "Indefinite" ? 700 : 400}
                    color={"#000000"}
                  >
                    90 days from{" "}
                    {dayjs(new Date()).add(1, "day").format("DD/MM/YYYY")}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Box></Box>
          <Grid item lg={6} sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              sx={[commonStyles.btnSecondary]}
              variant="contained"
              onClick={validateForm}
              disabled={loading}
            >
              <img src={images.check_square} alt="check" />
              <Typography sx={{ pl: 2 }}>
                {mode === "add" ? "Create" : "Update"}
              </Typography>
            </Button>
            <Button
              onClick={() => {
                handleCloseModel();
              }}
              sx={[commonStyles.btnPrimary]}
              variant="contained"
            >
              <Typography>{"Cancel"}</Typography>
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default Coupons;
