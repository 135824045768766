import login_bg from "../../assets/login_bg.png";
import logo from "../../assets/logo.png";
import dbProfile from "../../assets/profile.svg";
import dbDash from "../../assets/dashboard.svg";
import dbListing from "../../assets/listing.svg";
import dbStApp from "../../assets/studentApp.svg";
import dbStList from "../../assets/studentList.svg";
import dbSubs from "../../assets/subscribe.svg";
import dbClass from "../../assets/classSchedule.svg";
import dbIncome from "../../assets/income.svg";
import notify from "../../assets/bellIcon.svg";
import dbSetting from "../../assets/settingsIcon.svg";
import logout from "../../assets/logout.svg";
import edit from "../../assets/edit.svg";
import trash from "../../assets/trash.svg";
import preachIcon from "../../assets/preach.svg";
import growthTrend from "../../assets/growthTrend.svg";
import goAdd from "../../assets/goAddIcon.svg";
import check_square from "../../assets/check-square.svg";
import view from "../../assets/eye.svg";
import user from "../../assets/user.svg";
import coupon from "../../assets/coupon.svg";

export const images = {
  login_bg,
  logo,
  dbProfile,
  dbDash,
  dbListing,
  dbStApp,
  dbStList,
  dbSubs,
  dbIncome,
  dbClass,
  dbSetting,
  notify,
  logout,
  edit,
  user,
  trash,
  preachIcon,
  growthTrend,
  goAdd,
  check_square,
  view,
  coupon
};
