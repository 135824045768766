import React, {
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  AlertColor,
  Stack,
  Pagination,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { logout } from "../../../utils/redux";
import PageLoader from "../../../components/PageLoader";
import Notification from "../../../components/Notification";
import { styles } from "./styles";
import Alert from "../../../components/Alert";

interface Column {
  id: keyof ListingData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

export interface ListingData {
  id: number;
  courseName: string;
  startDate: string;
  startTime: string;
  endTime: string;
  teacher: string;
  student: string;
  status: string;
  teacherShare: number;
  platformShare: number;
  teacherPaymentStatus: string;
}

const columns: Column[] = [
  { id: "id", label: "Class ID", minWidth: 100, align: "left" },
  {
    id: "teacher",
    label: `Teacher Name`,
    minWidth: 150,
    align: "left",
  },
  {
    id: "student",
    label: `Student Name`,
    minWidth: 150,
    align: "left",
  },
  { id: "courseName", label: "Course Name", minWidth: 150, align: "left" },
  { id: "startDate", label: "Class Date", minWidth: 150, align: "left" },
  { id: "startTime", label: "Start Time", minWidth: 150, align: "left" },
  { id: "endTime", label: "End Time", minWidth: 150, align: "left" },
  { id: "teacherShare", label: "Teacher Share", minWidth: 150, align: "left" },
  { id: "platformShare", label: "Preplix Share", minWidth: 150, align: "left" },
  { id: "status", label: "Class Status", minWidth: 150, align: "left" },
  {
    id: "teacherPaymentStatus",
    label: "Teacher Payment Status",
    minWidth: 150,
    align: "left",
  },
];

const ClassSchedule = () => {
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: ListingData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        page: page,
        size: rowsPerPage,
      };
      const { data } = await axiosInstance.post(
        URLS.getAllBookingDetails("admin"),
        body
      );
      setLoading(false);
      const listings: ListingData[] = [];
      const today = dayjs().startOf("day");
      data.content.forEach((listing: any) => {
        listings.push({
          id: listing.id,
          courseName: listing.courseScheduleDetails.courseName,
          startDate: dayjs(listing.courseScheduleDetails.startDate).format(
            "YYYY-MM-DD"
          ),
          startTime: dayjs(listing.courseScheduleDetails.startDate).format(
            "hh:mm A"
          ),
          endTime: dayjs(listing.courseScheduleDetails.endDate).format(
            "hh:mm A"
          ),
          teacher: listing.teacher.firstName + " " + listing.teacher.lastName,
          student: listing.student.firstName + " " + listing.student.lastName,
          status: dayjs(listing.courseScheduleDetails.startDate).isSame(
            today,
            "day"
          )
            ? "Today"
            : dayjs(listing.courseScheduleDetails.startDate).isBefore(
                  today,
                  "day"
                )
              ? "Past"
              : "Upcoming",
          teacherShare: listing.revenueSplit
            ? listing.revenueSplit.teacherShare
            : "",
          platformShare: listing.revenueSplit
            ? listing.revenueSplit.platformShare
            : "",
          teacherPaymentStatus: listing.teacherPaymentStatus,
        });
      });
      setListing({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        listings,
      });
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch, page]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const changePaymentStatus = async (id: number, statusType :string) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.post(
        URLS.classPaymentStatus("admin",id,statusType),
      );
      setLoading(false);
      if (status === 200) {
        setMessage({
          display: true,
          severity: "success",
          message: `Status ${statusType} Successfully`,
        });
        await getData();
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const handleTeacherPayment = (row: ListingData) => {
    Alert({
      title: `Are you Sure you want to approve this?`,
      html: `<p><strong>Teacher Name: </strong>${row.teacher}</p><p><strong>Course Name: </strong>${row.courseName}</p><p><strong>Class Date: </strong>${row.startDate}</p><p><strong>Teacher Share: </strong>${row.teacherShare}</p><p><strong>Preplix Share: </strong>${row.platformShare}</p>`,
      confirmButtonText: `Yes, Approve`,
      confirmButtonColor: "#28a745",
      showCancelButton: true,
      cancelButtonText: "No, Take me Back",
      cancelButtonColor: "#686565",
      reverseButtons: true,
      denyButtonText: 'Reject',
      showDenyButton: true,
      denyButtonColor: "#D10724",
      customClass: { cancelButton: "delete-popup-cancel"},
    }).then(async (result) => {
      if (result.isConfirmed) {
        changePaymentStatus(row.id,"APPROVED");
      }else if(result.isDenied){
        changePaymentStatus(row.id,"REJECTED");
      }
    });
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#f4fbff",
        marginTop: "20px",
        padding: "20px",
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <Box>
        {loading && <PageLoader />}
        {message.display && (
          <Notification
            isOpen={message.display}
            message={message.message}
            severity={message.severity as AlertColor}
            closeNotification={closeNotification}
          />
        )}
        <Box>
          <Typography variant="h5" fontWeight="700" color={"#000000"} mb={2}>
            Class Scheduled
          </Typography>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            maxWidth: "calc(100vw - 105px)",
          }}
        >
          <TableContainer
            sx={{ maxHeight: "calc(100vh - 400px)", overflow: "auto" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{
                        minWidth: column.minWidth,
                        background: "#EBEBEB",
                        borderBottom: "1px solid #9D9D9D",
                        fontSize: "18px",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listing.listings.map((row) => (
                  <TableRow
                    style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                    hover
                    tabIndex={-1}
                    key={row.id}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      let textStyle: CSSProperties = {
                        fontSize: "16px",
                        fontWeight: "600",
                      };
                      return column.id === "teacherPaymentStatus" &&
                        value === "PENDING" && row.status==="Past" ? (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ padding: "25px" }}
                        >
                          <Button
                            variant="contained"
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              handleTeacherPayment(row);
                            }}
                            sx={{
                              borderRadius: "1rem",
                              paddingX: 3,
                            }}
                          >
                            <Typography>Actions</Typography>
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ padding: "25px" }}
                        >
                          <Typography sx={textStyle}>{value}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container sx={[{ padding: "15px 0" }]}>
            <Grid
              item
              lg={12}
              sx={[
                {
                  display: "flex",
                  justifyContent: "center",
                },
              ]}
            >
              <div
                style={{
                  width: "fitContent",
                  background: "#FDF3F4",
                  borderRadius: "24px",
                  padding: "20px",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    sx={styles.pagination}
                    count={listing.totalPages}
                    defaultPage={1}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default ClassSchedule;
