import React, { FC } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import InputField from "../InputField";
import DropDownInput from "../DropDownInput";
// import DateInput from "../DateInput/DateInput";
import { FormModel } from "./index";
import DateInput from "../DateInput/DateInput";
import dayjs from "dayjs";

// import dayjs from "dayjs";

interface FormFieldProps {
  value: string | number | boolean;
  item: FormModel;
  hasError?: boolean;
  fieldError?: string;
  handleChange: (
    value: string,
    field: string,
    error?: { error: boolean; message: string },
    deleted?: { deletedField: string; DeletedFile: string },
  ) => void;
}

const RenderFormFields: FC<FormFieldProps> = ({
  value,
  item,
  hasError,
  fieldError,
  handleChange,
}) => {
  switch (item.type) {
    case "divider":
      return (
        <Grid
          size={12}
          sx={{
            borderBottom: "1px",
            borderStyle: item.variant,
            borderColor: item.typeValue,
          }}
        />
      );
    case "line-break":
      return <Grid size={12} />;
    case "label":
      return (
        <Grid
          size={item.responsive}
          className={"form-group " + item.styleClass}
        >
          <Typography variant={item.variant} style={item.style} sx={item.sx}>
            {item.label}
          </Typography>
        </Grid>
      );
    case "text":
      return (
        <Grid
          size={item.responsive}
          className={"form-group " + item.styleClass}
        >
          <InputField
            disabled={item.disabled || false}
            autoFocus={item.autoFocus || false}
            variant={item.variant}
            size={item.size}
            // startAdornment={item.startAdornment}
            endAdornment={item?.endAdornment as string}
            hasError={hasError || false}
            field={item.field}
            inputValue={value || value === 0 ? (value as string) : ""}
            style={item.style}
            typeValue={item.typeValue || ""}
            label={!item.hiddenLabel ? item.label || "" : ""}
            fieldError={fieldError || ""}
            validators={item.validators}
            className={item.className}
            textChange={handleChange}
            sx={item.sx}
            hiddenLabel={item.hiddenLabel}
            rows={item.rows}
            multiline={!!item.rows && item.rows > 1}
            placeholder={item.placeholder}
            slotProps={{ htmlInput: item.inputProps || {} }}
          />
        </Grid>
      );
    case "drop-down":
      return (
        <Grid
          size={item.responsive}
          className={"form-group " + item.styleClass}
        >
          <DropDownInput
            options={
              item.options as {
                label: string;
                value: string;
                icon?: string;
              }[]
            }
            style={item.style}
            labelId={""}
            disabled={item.disabled || false}
            inputProps={item.inputProps || {}}
            hasError={hasError || false}
            field={item.field}
            inputValue={!!value || value === 0 ? (value as string) : ""}
            label={!item.hiddenLabel ? item.label || "" : ""}
            fieldError={fieldError || ""}
            validators={item.validators}
            textChange={handleChange}
            sx={item.sx}
            variant={"standard"}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid
          size={item.responsive}
          className={"form-group " + item.styleClass}
        >
          <DateInput
            label={!item.hiddenLabel ? item.label || "" : ""}
            validators={item.validators}
            textChange={handleChange}
            value={!!value ? dayjs(new Date(value as string)) : null}
            field={item.field}
            onChange={(e: any) => e}
            disablePast={item.disablePast}
            disableFuture={item.disableFuture}
            minDate={item.minDate}
            maxDate={item.maxDate}
            placeholder={item.placeholder}
            format={item.inputFormat}
            disabled={item.disabled}
            slotProps={{
              textField: {
                size: item.size,
                fullWidth: true,
                disabled: true,
              },
            }}
            views={item.views}
            sx={item.sx}
          />
        </Grid>
      );
    default:
      break;
  }
};

export default RenderFormFields;
