import { FormModel } from "../../../components/Form";
import { Validators } from "../../../utils/validators";

export const CouponForm:  (couponForm:any) => FormModel[] = (couponForm) => {
  let form = [];
  form.push(
    {
      label: "Coupon Type",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "discountType",
      options: [
        { label: "Percentage Discount", value: "PERCENTAGE" },
        { label: "Fixed Discount", value: "AMOUNT" },
      ],
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Discount Value",
      disabled: !couponForm?.discountType,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Discount Value",
      field: "discountAmount",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        {
          check: Validators.floatNumber,
          message: "Please enter a Valid Score",
        }
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Customer Type",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Customer Type",
      field: "customerType",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Applicable On",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "applicableOn",
      options: [
        { label: "New Customer", value: "NEW_USER" },
        { label: "Repeat Customer", value: "OLD_USER" },
      ],
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Description",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Description",
      field: "description",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Count of Usage",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Count of Usage",
      field: "usageLimit",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        {
          check: Validators.number,
          message: "Please enter a Valid Score",
        },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Minimum Cart Value",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Minimum Cart Value",
      field: "minPurchaseAmount",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        {
          check: Validators.number,
          message: "Please enter a Valid Score",
        },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Coupon Code",
      disabled: true,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Auto Generate",
      field: "code",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    }
  );
  return form;
};
