import React, {
    useState,
    CSSProperties,
    useEffect,
    useCallback,
    ChangeEvent,
  } from "react";
  import {
    Container,
    Grid,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    AlertColor,
    Stack,
    Pagination,
    Button,
  } from "@mui/material";
  import dayjs from "dayjs";
  import { useDispatch } from "react-redux";
  import axiosInstance from "../../../utils/axios";
  import { URLS } from "../../../utils/constants/urls";
  import { logout } from "../../../utils/redux";
  import PageLoader from "../../../components/PageLoader";
  import Notification from "../../../components/Notification";
  import { styles } from "./styles";
  import Alert from "../../../components/Alert";
  
  interface Column {
    id: keyof ListingData;
    label: string;
    minWidth: number;
    align: "right" | "left";
  }
  
  export interface ListingData {
    id: number;
    courseName: string;
    date: string;
    rating: string;
    comment: string;
    teacher: string;
    student: string;
    status: string;
  }
  
  const columns: Column[] = [
    { id: "id", label: "Rating ID", minWidth: 100, align: "left" },
    { id: "date", label: "Date", minWidth: 200, align: "left" },
    { id: "teacher", label: `Teacher Name`, minWidth: 150, align: "left",},
    { id: "student", label: `Student Name`, minWidth: 150, align: "left",},
    { id: "courseName", label: "Course Name", minWidth: 150, align: "left" },
    { id: "rating", label: "Rating", minWidth: 150, align: "left" },
    { id: "comment", label: "Comment", minWidth: 150, align: "left" },
    { id: "status", label: "Status", minWidth: 150, align: "left",},
  ];
  
  const Rating = () => {
    const dispatch = useDispatch();
    const rowsPerPage: number = 10;
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [listing, setListing] = useState<{
      totalElements: number;
      listings: ListingData[];
      totalPages: number;
    }>({ totalPages: 0, totalElements: 0, listings: [] });
    const [message, setMessage] = useState({
      display: false,
      severity: "",
      message: "",
    });
  
    const getData = useCallback(async (filter: string[]) => {
      try {
        setLoading(true);
        const body = {
          filter,
          graphql: null,
          page: page,
          size: rowsPerPage,
          sort: [`createdAt:desc`],
        };

        const { data } = await axiosInstance.post(
          URLS.reviewData,
          body
        );
         console.log(data);
        setLoading(false);
        const listings: ListingData[] = [];
        const today = dayjs().startOf("day");
        data.content.forEach((listing: any) => {
          listings.push({
            id: listing.id,
            courseName: listing.courseName,
            date: dayjs(listing.createdAt).format(
              "YYYY-MM-DD"
            ),
            rating: listing.rating,
            comment: listing.review,
            teacher: listing.teacherName,
            student: listing.studentName,
            status: listing.status,
          });
        });
        setListing({
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          listings,
        });
      } catch (e: any) {
        setLoading(false);
        if (e.response) {
          if (e.response.status === 401) {
            dispatch(logout());
          } else {
            setMessage({
              display: true,
              severity: "error",
              message: e.response.data.message,
            });
          }
        }
        console.log(e);
      }
    }, [dispatch, page]);
  
    useEffect(() => {
      (async () => {
        await getData([]);
      })();
    }, [getData]);
  
    const closeNotification = (value: boolean) => {
      setMessage((prevState) => ({ ...prevState, display: value }));
    };
  
    const handleChangePage = async (
      event: ChangeEvent<unknown>,
      newPage: number
    ) => {
      setPage(newPage);
    };
  
    const changeReviewStatus = async (id: number, statusType :string) => {
      try {
        setLoading(true);
        const { status } = await axiosInstance.put(
          URLS.reviewUpdate(id,statusType)
        );
        setLoading(false);
        console.log(status);
        if (status === 200) {
          setMessage({
            display: true,
            severity: "success",
            message: `Status ${statusType} Successfully`,
          });
          await getData([]);
        }
      } catch (e: any) {
        console.log(e);
        if (e.response) {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: "Something went wrong. Please try again after sometime",
          });
        }
        setLoading(false);
      }
    };
  
    const handleReview = (row: ListingData) => {
      Alert({
        title: `Are you Sure you want to approve this?`,
        html: `<p><strong>Teacher Name: </strong>${row.teacher}</p><p><strong>Student Name</strong>${row.student}</p><p><strong>Course Name: </strong>${row.courseName}</p><p><strong>Date: </strong>${row.date}</p><p><strong>Rating </strong>${row.rating}</p><p><strong>Comment: </strong>${row.comment}</p>`,
        confirmButtonText: `Yes, Approve`,
        confirmButtonColor: "#28a745",
        showCancelButton: true,
        cancelButtonText: "No, Take me Back",
        cancelButtonColor: "#686565",
        reverseButtons: true,
        denyButtonText: 'Reject',
        showDenyButton: true,
        denyButtonColor: "#D10724",
        customClass: { cancelButton: "delete-popup-cancel"},
      }).then(async (result) => {
        if (result.isConfirmed) {
          changeReviewStatus(row.id,"APPROVED");
        }else if(result.isDenied){
          changeReviewStatus(row.id,"REJECTED");
        }
      });
    };
  
    return (
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          backgroundColor: "#f4fbff",
          marginTop: "20px",
          padding: "20px",
          height: "auto",
          minHeight: "100vh",
        }}
      >
        <Box>
          {loading && <PageLoader />}
          {message.display && (
            <Notification
              isOpen={message.display}
              message={message.message}
              severity={message.severity as AlertColor}
              closeNotification={closeNotification}
            />
          )}
          <Box>
            <Typography variant="h5" fontWeight="700" color={"#000000"} mb={2}>
              Rating
            </Typography>
          </Box>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              maxWidth: "calc(100vw - 105px)",
            }}
          >
            <TableContainer
              sx={{ maxHeight: "calc(100vh - 400px)", overflow: "auto" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={"center"}
                        style={{
                          minWidth: column.minWidth,
                          background: "#EBEBEB",
                          borderBottom: "1px solid #9D9D9D",
                          fontSize: "18px",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listing.listings.map((row) => (
                    <TableRow
                      style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        let textStyle: CSSProperties = {
                          fontSize: "16px",
                          fontWeight: "600",
                        };
                        return column.id === "status"&&
                       ( value === "PENDING" || value === null) ? (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={{ padding: "25px" }}
                          >
                            <Button
                              variant="contained"
                              onClick={(
                                event: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                handleReview(row);
                              }}
                              sx={{
                                borderRadius: "1rem",
                                paddingX: 3,
                              }}
                            >
                              <Typography>Actions</Typography>
                            </Button>
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={{ padding: "25px" }}
                          >
                            <Typography sx={textStyle}>{value}</Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container sx={[{ padding: "15px 0" }]}>
              <Grid
                item
                lg={12}
                sx={[
                  {
                    display: "flex",
                    justifyContent: "center",
                  },
                ]}
              >
                <div
                  style={{
                    width: "fitContent",
                    background: "#FDF3F4",
                    borderRadius: "24px",
                    padding: "20px",
                  }}
                >
                  <Stack spacing={2}>
                    <Pagination
                      sx={styles.pagination}
                      count={listing.totalPages}
                      defaultPage={1}
                      shape="rounded"
                      onChange={handleChangePage}
                    />
                  </Stack>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    );
  };
  
  export default Rating;
  