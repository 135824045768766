import React, {
  FC,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AlertColor,
  Stack,
  Pagination,
  Divider,
} from "@mui/material";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { logout } from "../../../utils/redux";
import PageLoader from "../../../components/PageLoader";
import Notification from "../../../components/Notification";
import { variables } from "../../../utils/style/_variables";
import { styles } from "../Listing/styles";
import { useDispatch } from "react-redux";
import {
  MarketplaceColumn,
  MarketplaceRevenueData,
  PLCColumn,
} from "./Revenue";

interface RevenueListingProps {
  columnsList: MarketplaceColumn[] | PLCColumn[];
  type: "Marketplace" | "PLC";
  countersList: {
    name: string;
    keyName: string;
  }[];
}

const RevenueListing: FC<RevenueListingProps> = ({
  columnsList,
  type,
  countersList,
}) => {
  const columns =
    type === "Marketplace"
      ? (columnsList as MarketplaceColumn[])
      : (columnsList as PLCColumn[]);
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [countersData, setCountersData] = useState<any>({});

  const [listing, setListing] = useState<{
    totalElements: number;
    listings: MarketplaceRevenueData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        filter: [],
        graphql: null,
        role: "admin",
        page: page,
        size: rowsPerPage,
        sort: [],
      };
      const {
        data,
      }: {
        status: number;
        data: {
          size: number;
          content: [
            {
              payments: MarketplaceRevenueData[];
              [key: string]: number | MarketplaceRevenueData[];
            },
          ];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: number;
          totalElements: number;
          numberOfElements: number;
          numberOfElementsInDatabase: number;
        };
      } = await axiosInstance.post(URLS.get_transaction_admin, body);
      const listings: MarketplaceRevenueData[] = [];
      if (data.content.length > 0) {
        setListing({
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          listings: data.content[0].payments,
        });
        setCountersData(data.content[0]);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch, page]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        height: "auto",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Grid container mb={4}>
        <Grid item lg={4}>
          <Typography fontSize={"28px"} fontWeight={700} color={"black"}>
            {"Revenue History"}
          </Typography>
          <Divider
            sx={{
              width: "40%",
              height: "3px",
              background: `${variables.preplixPrimary}`,
            }}
          />
        </Grid>
      </Grid>
      <Grid container mb={4} rowGap={2} columnGap={3}>
        {countersList.map((counterItem) => {
          return (
            <Grid
              key={counterItem.name}
              xs={2}
              item
              p={2}
              style={{
                backgroundColor: "white",
                borderRadius: "16px",
                borderRight: `10px solid ${variables.preplixPrimary}`,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                fontWeight={600}
                fontSize={"17px"}
                mb={2}
                color={"black"}
              >
                {counterItem.name}
              </Typography>
              <Typography fontWeight={900} fontSize={"28px"} color={"black"}>
                {`₹ ${countersData[counterItem.keyName] || 0}`}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "20px",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            maxWidth: "calc(100vw - 105px)",
          }}
        >
          <TableContainer
            sx={{ maxHeight: "calc(100vh - 400px)", overflow: "auto" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{
                        minWidth: column.minWidth,
                        background: "#EBEBEB",
                        borderBottom: "1px solid #9D9D9D",
                        fontSize: "18px",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listing.listings.map((row) => (
                  <TableRow
                    style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                    hover
                    tabIndex={-1}
                    key={row.id}
                  >
                    {columns.map((column) => {
                      const value = (() => {
                        if (type === "Marketplace") {
                          if (column.id === "id") {
                            return `PRE-${row[column.id as keyof MarketplaceRevenueData]}`;
                          }
                          if (
                            column.id === "paymentDate" &&
                            row[column.id as keyof MarketplaceRevenueData] !==
                              null
                          ) {
                            const date = new Date(
                              row[column.id as keyof MarketplaceRevenueData]
                            );
                            return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`;
                          }
                          if (
                            column.id === "amount" &&
                            typeof row[
                              column.id as keyof MarketplaceRevenueData
                            ] === "number"
                          ) {
                            if(row.particular==="Withdrawal"){
                              return -row[column.id];
                            }else{
                              return `+${row[column.id]}`;
                            }
                          }
                          return (
                            row[column.id as keyof MarketplaceRevenueData] ??
                            "-"
                          );
                        }
                      })();

                      let textStyle: CSSProperties = {
                        fontSize: "16px",
                        fontWeight: "600",
                        ...(column.id === "amount" &&
                          typeof row[
                            column.id as keyof MarketplaceRevenueData
                          ] === "number" && {
                            color: row.particular==="Withdrawal" ? "red" : "green",
                          }),
                      };

                      return (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ padding: "25px" }}
                        >
                          <Typography sx={textStyle}>{value}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container sx={[{ padding: "15px 0" }]}>
            <Grid
              item
              lg={12}
              sx={[
                {
                  display: "flex",
                  justifyContent: "center",
                },
              ]}
            >
              <div
                style={{
                  width: "fitContent",
                  background: "#FDF3F4",
                  borderRadius: "24px",
                  padding: "20px",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    sx={styles.pagination}
                    count={listing.totalPages}
                    defaultPage={1}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
};

export default RevenueListing;
