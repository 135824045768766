import React, {
  FC,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid2 as Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AlertColor,
  Stack,
  Pagination,
  IconButton,
  Box,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import { logout } from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import Notification from "../../../components/Notification";
import PageLoader from "../../../components/PageLoader";
import { useDispatch } from "react-redux";
import { styles } from "./styles";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { images } from "../../../utils/constants/images";
import Alert from "../../../components/Alert";

interface Column {
  id: keyof WithdrawalListingData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const columns: Column[] = [
  { id: "id", label: "Ref ID", minWidth: 150, align: "left" },
  { id: "transaction_date", label: "Transaction Date", minWidth: 200, align: "left" },
  { id: "userName", label: "Teacher Name", minWidth: 250, align: "left" },
  { id: "bank_details", label: "Bank Details", minWidth: 200, align: "left" },
  { id: "amount", label: "Amount", minWidth: 150, align: "left" },
  {
    id: "status",
    label: "Action",
    minWidth: 150,
    align: "left",
  },
];


export interface WithdrawalListingData {
  id: number;
  transaction_date: string;
  userName: string;
  bank_details: string;
  amount: string;
  status: string,
}


const Withdrawal: FC = () => {
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;

  const [page, setPage] = useState<number>(1);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: WithdrawalListingData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const [filterText, setFilterText] = useState<string>("");

  const getData = useCallback(
    async (filter: string[]) => {
      try {
        setLoading(true);
        const body = {
          filter,
          graphql: null,
          page: page,
          size: rowsPerPage,
          sort: [`createdAt:desc`],
        };

        interface ApiData extends WithdrawalListingData {
          accountNo: string
          ifscCode: string
          createdAt: string
          withdrawalAmount: string
          status:string
        }

        const {
          data,
        }: {
          data: {
            size: number;
            content: ApiData[];
            number: number;
            sort: { sorted: boolean; empty: boolean; unsorted: boolean };
            first: boolean;
            last: true;
            totalPages: number;
            totalElements: number;
            numberOfElements: number;
            numberOfElementsInDatabase: number;
          };
        } = await axiosInstance.post(URLS.withdrawalPage, body);
        console.log(data);
        const listings: WithdrawalListingData[] = [];
        data.content.forEach((listing) => {
          listings.push({
            id: listing.id,
            bank_details: `${listing.accountNo} ,${listing.ifscCode}`,
            transaction_date: listing.createdAt,
            userName: listing.userName,
            amount: listing.withdrawalAmount,
            status: listing.status
          });
        });
        setListing({
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          listings,
        });

        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        if (e.response) {
          if (e.response.status === 401) {
            dispatch(logout());
          } else {
            setMessage({
              display: true,
              severity: "error",
              message: e.response.data.message,
            });
          }
        }
        console.log(e);
      }
    },
    [dispatch, page],
  );

  useEffect(() => {
    (async () => {
      await getData([]);
    })();
  }, [getData]);

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const changeWithdrawalStatus = async (id: number, statusType :string) => {
    try {
      setLoading(true);
      const body={
        paymentMethod: "online",
        paymentReference: "online",
        status: statusType
      }
      const { status } = await axiosInstance.post(
        URLS.update_Withdrawal(id),body
      );
      setLoading(false);
      if (status === 200) {
        setMessage({
          display: true,
          severity: "success",
          message: `Status ${statusType} Successfully`,
        });
        await getData([]);
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const handleWithdrawal = (row: WithdrawalListingData) => {
    Alert({
      title: `Are you Sure you want to approve this?`,
      html: `<p><strong>Teacher Name: </strong>${row.userName}</p><p><strong>Bank Detail: </strong>${row.bank_details}</p><p><strong>Transaction Date: </strong>${row.transaction_date}</p><p><strong>Amount: </strong>${row.amount}</p>`,
      confirmButtonText: `Yes, Approve`,
      confirmButtonColor: "#28a745",
      showCancelButton: true,
      cancelButtonText: "No, Take me Back",
      cancelButtonColor: "#686565",
      reverseButtons: true,
      denyButtonText: 'Reject',
      showDenyButton: true,
      denyButtonColor: "#D10724",
      customClass: { cancelButton: "delete-popup-cancel"},
    }).then(async (result) => {
      if (result.isConfirmed) {
        changeWithdrawalStatus(row.id,"APPROVED");
      }else if(result.isDenied){
        changeWithdrawalStatus(row.id,"REJECTED");
      }
    });
  };


  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Typography
        variant="h6"
        component="h6"
        p={3}
        textTransform={"capitalize"}
      >
        Withdrawal Listing
      </Typography>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "calc(100vw - 105px)",
        }}
      >
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 290px)", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{
                      minWidth: column.minWidth,
                      background: "#EBEBEB",
                      borderBottom: "1px solid #9D9D9D",
                      fontSize: "18px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listing.listings.map((row) => (
                <TableRow
                  style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  {columns.map((column, index) => {
                    const value = (() => {
                      if (column.id === "transaction_date") {
                        const date = new Date(row[column.id as keyof WithdrawalListingData]);
                        return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`;
                      }
                      return row[column.id as keyof WithdrawalListingData] ?? "-";
                    })();
                    let textStyle: CSSProperties = {
                      fontSize: "16px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    };
                    return column.id === "status" &&
                    value === "PENDING" ? (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ padding: "25px" }}
                      >
                        <Button
                          variant="contained"
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            handleWithdrawal(row);
                          }}
                          sx={{
                            borderRadius: "1rem",
                            paddingX: 3,
                          }}
                        >
                          <Typography>Actions</Typography>
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{ padding: "25px" }}
                      >
                        <Typography sx={textStyle}>
                          {value as unknown as string}
                        </Typography>

                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={[{ padding: "15px 0" }]}>
          <Grid
            size={12}
            sx={[
              {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            <div
              style={{
                width: "fitContent",
                background: "#FDF3F4",
                borderRadius: "24px",
                padding: "20px",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  sx={styles.pagination}
                  count={listing.totalPages}
                  defaultPage={1}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Withdrawal;
