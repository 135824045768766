import { Box, Container, Tab, Tabs } from "@mui/material";
import React, { FC } from "react";
import RevenueListing from "./RevenueListing";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const counterList = [
  {
    name: "Total Revenue",
    keyName: "totalPlatformRevenue",
  },
  {
    name: "Preplix Net",
    keyName: "totalNetPlatformRevenue",
  },
  {
    name: "Preplix Profit",
    keyName: "totalPlatformProfit",
  },
  {
    name: "Total Tax",
    keyName: "totalTax",
  },
  {
    name: "Teacher's Subscription Revenue",
    keyName: "totalTeacherSubscriptionRevenue",
  },

  {
    name: "Student's Subscription Revenue",
    keyName: "totalStudentSubscriptionRevenue",
  },

  {
    name: "Teacher's Earning",
    keyName: "totalTeacherEarning",
  },

  {
    name: "Teacher's Withdrawal",
    keyName: "totalTeacherWithdrawal",
  },

  {
    name: "Teacher's Outstanding",
    keyName: "totalTeacherOutstanding",
  },

  {
    name: "Teacher's Earning Referral",
    keyName: "totalTeacherReferralRevenue",
  },

  {
    name: "Student's Earning Referral",
    keyName: "totalStudentReferralRevenue",
  },
];

function CustomTabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export interface MarketplaceColumn {
  id: keyof MarketplaceRevenueData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

export interface PLCColumn {
  id: keyof MarketplaceRevenueData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const marketPlaceColumns: MarketplaceColumn[] = [
  { id: "id", label: "Prep. Ref. ID", minWidth: 120, align: "left" },
  {
    id: "paymentDate",
    label: "Transaction Date",
    minWidth: 150,
    align: "left",
  },
  { id: "customerName", label: "Customer Name", minWidth: 200, align: "left" },
  { id: "customerType", label: "Customer Type", minWidth: 120, align: "left" },
  { id: "particular", label: "Particular", minWidth: 120, align: "left" },
  { id: "amount", label: "Amount", minWidth: 120, align: "left" },
  { id: "tax", label: "Tax", minWidth: 120, align: "left" },
  { id: "platformShare", label: "Preplix Share", minWidth: 120, align: "left" },
  { id: "teacherShare", label: "Teacher Share", minWidth: 120, align: "left" },
  {
    id: "platformRevenue",
    label: "Preplix Revenue",
    minWidth: 120,
    align: "left",
  },
  {
    id: "netPlatformRevenue",
    label: "Preplix Net",
    minWidth: 120,
    align: "left",
  },

  {
    id: "teacherEarning",
    label: "Teacher Earning",
    minWidth: 120,
    align: "left",
  },

  {
    id: "teacherWithdrawal",
    label: "Teacher Withdrawal",
    minWidth: 120,
    align: "left",
  }
];

export interface MarketplaceRevenueData {
  id: string;
  paymentDate: string;
  customerName: string;
  customerType: number;
  platformType: number;
  particular: string;
  amount: number;
  platformShare: number;
  numberOfClasses: number;
  studentReferralRevenue: number;
  teacherReferralRevenue: number;
  teacherEarning: number;
  platformRevenue: number;
  netPlatformRevenue: number;
  teacherWithdrawal: number;
  teacherShare: number;
  teacherOutstanding: number;
  tax: number;
}

const Revenue: FC = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#f4fbff",
        padding: "20px 0px",
        height: "auto",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            ml: 3,
            mr: 3,
            width: "fit-content",
          }}
        >
          <Tabs
            textColor="inherit"
            indicatorColor="primary"
            sx={{
              ".MuiTabs-indicator": {
                backgroundColor: "#D10724",
              },
              ".Mui-selected": {
                color: "#D10724",
              },
            }}
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab label="Marketplace" />
            <Tab label="PLC" />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <RevenueListing
            columnsList={marketPlaceColumns}
            type="Marketplace"
            countersList={counterList}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          PLC
        </CustomTabPanel>
      </Box>
    </Container>
  );
};

export default Revenue;
