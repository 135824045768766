import React, {
  FC,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  AlertColor,
  Stack,
  Pagination,
  Modal,
  Box,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { logout } from "../../../utils/redux";
import {
  FeesForm,
  CourseForm,
  LocationForm,
  StatusForm,
} from "./AddCourseForm";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import Notification from "../../../components/Notification/Notification";
import Alert from "../../../components/Alert/index";
import PageLoader from "../../../components/PageLoader/index";
import { styles } from "./styles";
import { images } from "../../../utils/constants/images";
import { Close } from "@mui/icons-material";
import { FormModel } from "../../../components/Form";
import RenderFormFields from "../../../components/Form/helper";
import Button from "../../../components/Button/Button";
import { DropDown } from "../../../utils/constants/identifiers";
import VerifiedIcon from "@mui/icons-material/Verified";
import AutoComplete from "../../../components/AutoComplete";
import { AutoCompleteOption } from "../../../components/AutoComplete/AutoComplete";

interface Column {
  id: keyof ListingData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const columns: Column[] = [
  { id: "id", label: "Class ID", minWidth: 100, align: "left" },
  { id: "courseName", label: "Course", minWidth: 80, align: "left" },
  { id: "teacherName", label: "Teacher's Name", minWidth: 80, align: "left" },
  {
    id: "fees",
    label: "Fees (for 1 class)",
    minWidth: 150,
    align: "left",
  },
  {
    id: "fees5",
    label: "Fees (for 5 classes)",
    minWidth: 150,
    align: "left",
  },
  {
    id: "fees10",
    label: "Fees (for 10 classes)",
    minWidth: 150,
    align: "left",
  },
  { id: "country", label: "Country", minWidth: 100, align: "left" },
  { id: "state", label: "State", minWidth: 100, align: "left" },
  { id: "city", label: "City", minWidth: 100, align: "left" },
  { id: "locality", label: "Area", minWidth: 130, align: "left" },
  {
    id: "mode",
    label: "Mode of Learning",
    minWidth: 100,
    align: "left",
  },
  { id: "language", label: "Language", minWidth: 100, align: "left" },
  { id: "status", label: "Status", minWidth: 90, align: "left" },
];

export interface ListingData {
  id?: string;
  courseName: string;
  teacherName: string;
  fees: string;
  fees5: string;
  fees10: string;
  prime?: boolean;
  primary?: boolean;
  mode: string;
  country: string;
  city: string;
  language: string;
  state: string;
  locality: string;
  status: string;
  preparation: string;
}

export interface ListingPayload extends Omit<ListingData, 'teacherName'> {
  teacherId: number;
}


const CoursePage: FC = () => {
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<ListingData>();
  const [courses, setCourses] = useState<DropDown[]>([]);
  const [teachers, setTeachers] = useState<DropDown[]>([]);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: ListingData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [listingForm, setListingForm] = useState<ListingData>();
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const [teacher,setTeacher] = useState<AutoCompleteOption | null>(null);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        filter: [],
        graphql: null,
        page: page,
        size: rowsPerPage,
        sort: [`id:asc`],
      };

      interface ApiData extends ListingData {
        teacher: {
          firstName: string;
          lastName: string;
        };
      }

      const {
        data,
      }: {
        status: number;
        data: {
          size: number;
          content: ApiData[];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: number;
          totalElements: number;
          numberOfElements: number;
        };
      } = await axiosInstance.post(URLS.view_listing("admin"), body);
      const listings: ListingData[] = [];
      data.content.forEach((listing) => {
        listings.push({
          id: listing.id,
          courseName: listing.courseName,
          teacherName: `${listing.teacher.firstName} ${listing.teacher.lastName}`,
          fees: listing.fees,
          fees5: listing.fees5,
          fees10: listing.fees10,
          prime: listing.prime,
          mode: listing.mode,
          country: listing.country,
          city: listing.city,
          language: listing.language,
          state: listing.state,
          locality: listing.locality,
          status: !!listing.status ? listing.status.toLowerCase() : "pending",
          preparation: listing.preparation || "false",
        });
      });
      setListing({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        listings,
      });
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch, page]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const handleDelete = async (row: ListingData) => {
    Alert({
      title: `Are you Sure you want to Delete this Listing?`,
      html: `<p><strong>Course: </strong>${row.courseName}</p><p><strong>Mode of Learning: </strong>${row.mode}</p><p><strong>Location: </strong>${row.locality}, ${row.city}</p>`,
      confirmButtonText: "Yes, Delete",
      confirmButtonColor: "#D10724",
      showCancelButton: true,
      cancelButtonText: "No, Take me Back",
      cancelButtonColor: "#D9D9D9",
      reverseButtons: true,
      customClass: { cancelButton: "delete-popup-cancel" },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteListing(row.id as string);
      }
    });
  };

  const deleteListing = async (id: string) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.delete(
        `${URLS.course("teachers")}/${id}`,
      );
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Course Deleted Successfully",
        });
        await getData();
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const handleCloseModal = async (flag?: boolean) => {
    if (!!flag) {
      await getData();
    }
    setOpenModal(false); // Close the modal
  };

  const getCourses = useCallback(
    async (flag: "true" | "false") => {
    try {
      const { data }: { data: { id: number; name: string }[] } =
        await axiosInstance.get(URLS.course_list(flag));
      setCourses(
        data.map((course) => ({ label: course.name, value: course.name })),
      );
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
      },
    [dispatch],
  );

  const editListing = async (item: ListingData) => {
    setRowData(item);
    setListingForm({ ...item, primary: item.prime });
    await getCourses(item.preparation as "true" | "false");
    setOpenModal(true);
  };

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const updateListing = async () => {
    try {
      if (!!rowData && !!listingForm) {
        setLoading(true);
        const { status } = await axiosInstance.patch(
          `${URLS.course("admin")}/${rowData.id}`,
          { ...listingForm, status: listingForm.status.toUpperCase() },
        );
        if (status === 200) {
          setLoading(false);
          setMessage({
            display: true,
            severity: "success",
            message: "Course Updated Successfully",
          });
          await handleCloseModal(true);
          await getData();
        }
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const approveListing = async (row: ListingData) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.patch(
        `${URLS.course("admin")}/${row.id as string}`,
        { ...row, primary: row.prime, status: "APPROVED" },
      );
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Listing Approved Successfully",
        });
        await getData();
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const getTeachers = async (input: string,
    callback: (data: AutoCompleteOption[]) => void) => {
    try {
      const { data } = await axiosInstance.get(
        `${URLS.teacher_list}?name=${input}`
      );
      if (data) {
        const updatedData = data
          .sort((a: AutoCompleteOption, b: AutoCompleteOption) =>
            a.name.localeCompare(b.name)
          )
          .map((item: AutoCompleteOption) => {
            return {
              id: item.id,
              name: item.id + " - " + item.name,
            };
          });
        callback(updatedData as AutoCompleteOption[]);
      }
      
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  };


  const AddListing = async () => {
    try {
     if(listingForm && listingForm.courseName && listingForm.mode && listingForm.language && teacher){
      setLoading(true);
      // Prepare the data for the API
      const payload = {
        ...listingForm,
        status: listingForm?.status?.toUpperCase(),
        teacherId: teacher.id
      };
      console.log(listingForm);
      const { status } = await axiosInstance.post(URLS.course("admin"), payload);
      if (status === 201) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Course Added Successfully",
        });
        setListingForm({} as ListingData);
        handleCloseAddModal();
      }
      await getData();
    }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };
  


  const addlistingModal = async () => {
    setListingForm({} as ListingData);
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setListingForm({} as ListingData);
    setOpenAddModal(false); // Close the modal
    setTeacher(null);
    setCourses([]);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Typography
        variant="h6"
        component="h6"
        p={3}
        textTransform={"capitalize"}
      >
        Course Listing
      </Typography>
      <Grid
        container
        spacing={4}
        justifyContent={"start"}
        sx={[{ py: 2 }]}
      >
        <Grid item lg={2}>
          <Card
            sx={{
              minHeight: "125px",
              borderRadius: "12px",
              background: "linear-gradient(180deg, #E30928 0%, #820517 100%)",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => addlistingModal()}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 0,
                "&:last-child": {
                  paddingBottom: 0,
                },
              }}
            >
              <img
                src={images.goAdd}
                alt="addlistingModal"
                className={"logo-icon-dashboard"}
              />
              <Typography
                variant="h5"
                textAlign="center"
                fontWeight="700"
                fontSize="20px"
              >
                Add New Listing
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "calc(100vw - 105px)",
        }}
      >
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 290px)", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{
                      minWidth: column.minWidth,
                      background: "#EBEBEB",
                      borderBottom: "1px solid #9D9D9D",
                      fontSize: "18px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  align={"center"}
                  style={{
                    minWidth: 200,
                    background: "#EBEBEB",
                    borderBottom: "1px solid #9D9D9D",
                    fontSize: "18px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listing.listings.map((row) => (
                <TableRow
                  style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  {columns.map((column) => {
                    const value =
                      column.id !== "id"
                        ? row[column.id as keyof ListingData]
                        : `PRE-${row[column.id as keyof ListingData]}`;
                    let textStyle: CSSProperties = {
                      fontSize: "16px",
                      fontWeight: "600",
                    };

                    if (column.id === "status") {
                      if (value === "pending") {
                        textStyle = {
                          ...textStyle,
                          textTransform: "capitalize",
                          color: "#FF9924",
                          backgroundColor: "#FF992426",
                          borderRadius: "10px",
                          padding: "10px",
                        };
                      } else if (value === "approved") {
                        textStyle = {
                          ...textStyle,
                          textTransform: "capitalize",
                          color: "#126616",
                          backgroundColor: "#12661626",
                          borderRadius: "10px",
                          padding: "10px",
                        };
                      }
                    }
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ padding: "25px" }}
                      >
                        <Typography sx={textStyle}>{value}</Typography>
                      </TableCell>
                    );
                  })}
                  <TableCell align={"center"} sx={{ padding: "25px" }}>
                    {/* Approve button */}
                    {row.status === "pending" && (
                      <IconButton
                        key="edit"
                        color="default"
                        aria-label="approve"
                        onClick={() => approveListing(row)}
                      >
                        <VerifiedIcon color={"success"} />
                      </IconButton>
                    )}
                    {/* // Edit button */}
                    <IconButton
                      key="edit"
                      color="default"
                      aria-label="edit"
                      onClick={() => editListing(row)}
                    >
                      <img
                        src={images.edit}
                        alt="edit"
                        className={"logo-icon-dashboard"}
                      />
                    </IconButton>
                    <IconButton
                      key="delete"
                      color="default"
                      aria-label="delete"
                      onClick={() => handleDelete(row)}
                    >
                      <img
                        src={images.trash}
                        alt="addlistingModal"
                        className={"logo-icon-dashboard"}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={[{ padding: "15px 0" }]}>
          <Grid
            item
            lg={12}
            sx={[
              {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            <div
              style={{
                width: "fitContent",
                background: "#FDF3F4",
                borderRadius: "24px",
                padding: "20px",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  sx={styles.pagination}
                  count={listing.totalPages}
                  defaultPage={1}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Box sx={styles.modalContent}>
          <IconButton
            onClick={() => handleCloseModal()} // Close the modal when IconButton is clicked
            sx={{ position: "absolute", top: 5, right: 5, zIndex: 1 }}
          >
            <Close />
          </IconButton>
          {!!rowData && (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit {rowData.courseName}
            </Typography>
          )}
          <Divider />

          <Grid container maxWidth={"lg"} sx={{ m: 2 }}>
            <Grid item lg={6}>
              {CourseForm(courses, teachers)
                .map((item: FormModel) => ({
                  ...item,
                  value: !!listingForm
                    ? (listingForm[item.field as keyof ListingData] as string | boolean)
                    : "",
                }))
                .filter((item: FormModel) => item.label !== "Choose Teachers" && item.field !== "preparation")
                .map((item: FormModel, index: number) => (
                  <Grid key={index} padding={2}>
                    <Typography
                      fontWeight={600}
                      fontSize={"18px"}
                      color="#545454"
                    >
                      {item.label}
                    </Typography>
                    <RenderFormFields
                      value={item.value as string}
                      item={item}
                      handleChange={async (value: string, field: string) => {
                        if (field === "preparation") {
                          await getCourses(value as "true" | "false");
                        }
                        const data = {
                          [field]: value,
                        };
                        setListingForm((prevState: any) => ({
                          ...prevState,
                          ...data,
                        }));
                      }}
                    />
                  </Grid>
                ))}
            </Grid>


            <Grid item lg={6} sx={{ pt: 2 }}>
              <Typography fontWeight={600} fontSize={"18px"} color="#545454">
                {"Fees (INR)"}
              </Typography>
              {FeesForm()
                .map((item: FormModel) => ({
                  ...item,
                  value: !!listingForm
                    ? (listingForm[item.field as keyof ListingData] as
                      | string
                      | boolean)
                    : "",
                }))
                .map((item: FormModel, index: number) => (
                  <Grid key={index} item padding={2}>
                    <Grid container>
                      <Grid
                        sx={[{ display: "flex", alignItems: "center" }]}
                        item
                        lg={4}
                      >
                        <Typography>{item.label}</Typography>
                      </Grid>
                      <Grid item lg={8}>
                        <RenderFormFields
                          value={item.value as string}
                          item={item}
                          handleChange={(value: string, field: string) => {
                            const data = {
                              [field]: value,
                            };
                            setListingForm((prevState: any) => ({
                              ...prevState,
                              ...data,
                            }));
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid container sx={{ m: 2 }}>
            <Typography
              fontWeight={600}
              fontSize={"18px"}
              color="#545454"
              sx={{ ml: 2 }}
            >
              {"Location"}
            </Typography>
            <Grid container>
              {LocationForm(
                (listingForm as {
                  country: string;
                  state: string;
                  city: string;
                }) || {
                  country: "",
                  state: "",
                  city: "",
                },
              )
                .map((item: FormModel) => ({
                  ...item,
                  value: !!listingForm
                    ? (listingForm[item.field as keyof ListingData] as
                      | string
                      | boolean)
                    : "",
                }))
                .map((item: FormModel, index: number) => (
                  <Grid key={index} item lg={6} padding={2}>
                    <Typography
                      fontWeight={600}
                      fontSize={"18px"}
                      color="#545454"
                    >
                      {item.label}
                    </Typography>
                    <RenderFormFields
                      value={item.value as string}
                      item={item}
                      handleChange={(value: string, field: string) => {
                        const data = {
                          [field]: value,
                        };
                        setListingForm((prevState: any) => ({
                          ...prevState,
                          ...data,
                        }));
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid container justifyContent={"flex-end"} sx={{ m: 2 }}>
            {StatusForm()
              .map((item: FormModel) => ({
                ...item,
                value: !!listingForm
                  ? (listingForm[item.field as keyof ListingData] as
                    | string
                    | boolean)
                  : "",
              }))
              .map((item: FormModel, index: number) => (
                <Grid key={index} item lg={6} padding={2}>
                  <Typography
                    fontWeight={600}
                    fontSize={"18px"}
                    color="#545454"
                  >
                    {item.label}
                  </Typography>
                  <RenderFormFields
                    value={item.value as string}
                    item={item}
                    handleChange={(value: string, field: string) => {
                      const data = {
                        [field]: value,
                      };
                      setListingForm((prevState: any) => ({
                        ...prevState,
                        ...data,
                      }));
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid container maxWidth={"lg"} sx={{ margin: "auto" }}>
            <Grid
              item
              lg={12}
              sx={{ display: "flex", justifyContent: "right" }}
            >
              <Button
                sx={[styles.btnSecondary]}
                variant="contained"
                onClick={updateListing}
                disabled={loading}
              >
                <img src={images.check_square} alt="check" />
                <Typography sx={{ pl: 2 }}>Update</Typography>
              </Button>
              <Button
                onClick={() => handleCloseModal()}
                sx={[styles.btnPrimary]}
                variant="contained"
              >
                <Typography>{"Cancel"}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={openAddModal} onClose={() => handleCloseAddModal()}>
        <Box sx={styles.modalContent}>
          <IconButton
            onClick={() => handleCloseAddModal()} // Close the modal when IconButton is clicked
            sx={{ position: "absolute", top: 5, right: 5, zIndex: 1 }}
          >
            <Close />
          </IconButton>

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Listing
          </Typography>
          <Divider />

          <Grid container maxWidth={"lg"} sx={{ m: 2 }}>
            <Grid item lg={6}>
              {CourseForm(courses, teachers)
                .map((item: FormModel) => ({
                  ...item,
                  value: !!listingForm
                    ? (listingForm[item.field as keyof ListingData] as
                      | string
                      | boolean)
                    : "",
                }))
                .map((item: FormModel, index: number) => (
                  <Grid key={index} padding={2}>
                    <Typography
                      fontWeight={600}
                      fontSize={"18px"}
                      color="#545454"
                    >
                      {item.label}
                    </Typography>
                    {item.field === "teacherId" ? (
                            <AutoComplete
                              showIcon={false}
                              fetchData={getTeachers}
                              noOptionsText="No Students"
                              value={teacher}
                              setValue={setTeacher}
                            />
                          ) : <RenderFormFields
                          value={item.value as string}
                          item={item}
                          handleChange={async(value: string, field: string) => {
                            if (field === "preparation") {
                              await getCourses(value as "true" | "false");
                            }
                            const data = {
                              [field]: value,
                            };
                            setListingForm((prevState: any) => ({
                              ...prevState,
                              ...data,
                            }));
                          }}
                        />}
                  </Grid>
                ))}
            </Grid>

            <Grid item lg={6} sx={{ pt: 2 }}>
              <Typography fontWeight={600} fontSize={"18px"} color="#545454">
                {"Fees (INR)"}
              </Typography>
              {FeesForm()
                .map((item: FormModel) => ({
                  ...item,
                  value: !!listingForm
                    ? (listingForm[item.field as keyof ListingData] as
                      | string
                      | boolean)
                    : "",
                }))
                .map((item: FormModel, index: number) => (
                  <Grid key={index} item padding={2}>
                    <Grid container>
                      <Grid
                        sx={[{ display: "flex", alignItems: "center" }]}
                        item
                        lg={4}
                      >
                        <Typography>{item.label}</Typography>
                      </Grid>
                      <Grid item lg={8}>
                        <RenderFormFields
                          value={item.value as string}
                          item={item}
                          handleChange={(value: string, field: string) => {
                            const data = {
                              [field]: value,
                            };
                            setListingForm((prevState: any) => ({
                              ...prevState,
                              ...data,
                            }));
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid container sx={{ m: 2 }}>
            <Typography
              fontWeight={600}
              fontSize={"18px"}
              color="#545454"
              sx={{ ml: 2 }}
            >
              {"Location"}
            </Typography>
            <Grid container>
              {LocationForm(
                (listingForm as {
                  country: string;
                  state: string;
                  city: string;
                }) || {
                  country: "",
                  state: "",
                  city: "",
                },
              )
                .map((item: FormModel) => ({
                  ...item,
                  value: !!listingForm
                    ? (listingForm[item.field as keyof ListingData] as
                      | string
                      | boolean)
                    : "",
                }))
                .map((item: FormModel, index: number) => (
                  <Grid key={index} item lg={6} padding={2}>
                    <Typography
                      fontWeight={600}
                      fontSize={"18px"}
                      color="#545454"
                    >
                      {item.label}
                    </Typography>
                    <RenderFormFields
                      value={item.value as string}
                      item={item}
                      handleChange={(value: string, field: string) => {
                        const data = {
                          [field]: value,
                        };
                        setListingForm((prevState: any) => ({
                          ...prevState,
                          ...data,
                        }));
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid
            item
            lg={6}
            sx={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              sx={[styles.btnSecondary]}
              variant="contained"
              onClick={AddListing}
              disabled={loading}
            >
              <img src={images.check_square} alt="check" />
              <Typography sx={{ pl: 2 }}>Save</Typography>
            </Button>
            <Button
              onClick={() => {
                handleCloseAddModal()
              }}
              sx={[styles.btnPrimary]}
              variant="contained"
            >
              <Typography>{"Cancel"}</Typography>
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};
export default CoursePage;
