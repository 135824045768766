import { FormModel } from "../../../components/Form";
import { DropDown } from "../../../utils/constants/identifiers";
import { Validators } from "../../../utils/validators";


export const BasicInformationApplicationForm: (
  teachers: DropDown[],
  mode: string,
) => FormModel[] = (teachers, mode) => {
  let form = [];
  if (mode !== "Edit") {
    form.push({
      label: "Student Name",
      value: "",
      size: "small",
      type: "auto-complete",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "studentId",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Teacher Name",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "teacherId",
      options: teachers,
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    });
  }
  form.push(
    {
      label: "Marital Status",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "maritalStatus",
      options: [
        { label: "MARRIED", value: "married" },
        { label: "UNMARRIED", value: "unmarried" },
      ],
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },

    {
      label: "Country",
      value: "",
      size: "small",
      type: "auto-complete",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "countryId",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "University Name",
      value: "",
      size: "small",
      type: "auto-complete",
      typeValue: "text",
      variant: "outlined",
      placeholder: "University Name",
      field: "universityId",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Intake Date",
      value: "",
      views: ["month","year"] as any,
      inputFormat: "MM-YYYY",
      size: "medium",
      type: "date",
      typeValue: "text",
      variant: "outlined",
      placeholder: "MM/YYYY",
      field: "intakeDate",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Preferred Course",
      value: "",
      size: "small",
      type: "auto-complete",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "courseId",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },

    {
      label: "Passport No",
      value: "",
      size: "medium",
      // autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Passport No",
      field: "passportNo",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },

    {
      label: "DOB",
      value: "",
      size: "medium",
      type: "date",
      typeValue: "text",
      variant: "outlined",
      placeholder: "DD/MM/YYYY",
      field: "dob",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },

    {
      label: "English Test Type",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "englishTestType",
      options: [
        { label: "IELTS", value: "ielts" },
        { label: "PTE", value: "pte" },
        { label: "TOEFL", value: "toefl" },
        { label: "Cambridge", value: "cambridge" },
        { label: "Others", value: "" },
      ],
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },

    {
      label: "Date of Test",
      value: "",
      size: "medium",
      type: "date",
      typeValue: "text",
      variant: "outlined",
      placeholder: "DD/MM/YYYY",
      field: "dateOfTest",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Listening Score",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "listeningRating",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        {
          check: Validators.floatNumber,
          message: "Please enter a Valid Score",
        },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Reading Score",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "readingRating",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        {
          check: Validators.floatNumber,
          message: "Please enter a Valid Score",
        },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Writing Score",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "writingRating",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        {
          check: Validators.floatNumber,
          message: "Please enter a Valid Score",
        },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Speaking Score",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "speakingRating",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        {
          check: Validators.floatNumber,
          message: "Please enter a Valid Score",
        },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    }
  );
  return form;
};

export const AdminEditableForm: (
  coeStatus: DropDown[],
  verificationStatus: DropDown[],
  admissionCounselor: DropDown[],
  applicationStatus: DropDown[],
  visaStatus: DropDown[]) => FormModel[] = (coeStatus,verificationStatus,admissionCounselor,applicationStatus,visaStatus) => {
  let form = [];

  form.push(
    {
      label: "Admission Counselor",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "admissionCounselor",
      options: admissionCounselor,
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "12px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Application Status",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "applicationStatus",
      options: applicationStatus,
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "12px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Verification Status",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "verificationStatus",
      options: verificationStatus,
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "12px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "COE Status",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "coeStatus",
      options: coeStatus,
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "12px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Visa Status",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "visaStatus",
      options: visaStatus,
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "12px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "VISA REFUSAL REASON",
      value: "",
      size: "medium",
      // autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "VISA REFUSAL REASON",
      field: "visaRefusalReason",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    }
  );
  return form;
};

export const NocForm: (teachers: DropDown[]) => FormModel[] = (teachers) => {
  let form = [];

  form.push(
    {
      label: "New Teacher",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "teacherId",
      options: teachers,
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "12px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Reason",
      value: "",
      size: "medium",
      // autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Reason",
      field: "reason",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "12px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    }
  );

  return form;
};
