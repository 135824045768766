const variables = {
    preplixPrimary: "#D10724",
    sectionPrimary: "#ECF8FF",
    preplixCards: "#2D83C50D",
  };
  
  export const styles = {
    pagination: {
      "& .Mui-selected": {
        backgroundColor: `${variables.preplixPrimary} !important`,
        color: "white",
      },
    }
  };
  